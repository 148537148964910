import React, { Component, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useHistory, useLocation, withRouter } from "react-router";
import { AnimatedSwitch } from "react-router-transition";
import Home from "./components/Home/Home";
import Header from "./components/Header/Header";
import PageNotFound from "./components/404/404";
import Footer from "./components/Footer/Footer";
import Login from "./components/Auth/Login/Login";
import Signup from "./components/Auth/Signup/Signup";
import ForgotPassword from "./components/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword/ResetPassword";
import VerifyEmail from "./components/Auth/VerifyEmail/VerifyEmail";
import MagicLogin from "./components/Auth/MagicLogin/MagicLogin";
import MyAccount from "./components/MyAccount/MyAccount";
// import MyApi from "./components/MyAccount/MyApi/MyApi";
// import ApiUsage from "./components/MyAccount/ApiUsage/ApiUsage";
import WordpressPlugin from "./components/Pages/WordpressPlugin/WordpressPlugin";
import DeveloperApi from "./components/Pages/DeveloperApi/DeveloperApi";
import Terms from "./components/Pages/Terms";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
import Logout from "./components/Auth/Logout";
import Thankyou from "./components/MyAccount/Thankyou";
import { PublicRoute, PrivateRoute } from "./config/Api";
import ScrollToTop from "./ScrollToTop";
import { isLogIn } from "./config/Api";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './responsive.css';

function App() {
  let location = useLocation();
  let loc = location.pathname
  useEffect(() => {
  }, [isLogIn()])
  return (
    <div className="main_id main_outer">
      <Header
        headerClass={(loc == "/login" || loc == "/signup" || loc == "/forgot-password" || loc == "/update-password" || loc == "/") ? "loginpage" : "static_header"}
        loginClass={loc == "/login" || loc == "/signup" || loc == "/forgot-password" || loc == "/update-password" ? "loginlink" : ""}
      />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute exact path="/signup" component={Signup} />
        <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
        <PublicRoute exact path="/update-password" component={ResetPassword} />
        <PublicRoute exact path="/verify-email" component={VerifyEmail} />
        <PublicRoute exact path="/magic-login" component={MagicLogin} />
        <PrivateRoute exact path="/logout-successfully" component={Logout} />
        <PrivateRoute exact path="/my-account" component={MyAccount} />
        <PrivateRoute exact path="/my-apis" component={MyAccount} />
        <PrivateRoute exact path="/my-usages" component={MyAccount} />
        <PrivateRoute exact path="/thankyou/:id" component={Thankyou} />
        <Route exact path="/developer-api" component={DeveloperApi} />
        <Route exact path="/wordpress-plugin" component={WordpressPlugin} />
        <Route exact path="/terms-of-use" component={Terms} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route component={PageNotFound} />
      </Switch>
      {loc === "/login" || loc == "/signup" || loc == "/forgot-password" || loc == "/update-password" ?
        null
        :
        <Footer />
      }
    </div>
  )
}

export default withRouter(App);
