import React from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import rocket from "../../../assets/images/wordpress-rocket.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Faq from "./Faq";
import { isLogIn } from "../../../config/Api";
import { Helmet } from "react-helmet";

const WordpressPlugin = () => {
    return (
        <>
            <Helmet>
                <title>Download PNG Compressor WordPress Plugin! It's Free.</title>
            </Helmet>
            <div className="wrdprsspgehdr ">
                <div className="container">
                    <div className="header-bnr ">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="header-txt-hldr">
                                    <h1> Speed up your WordPress Website</h1>
                                    <p>Images are the major contributors to size increment of a given webpage. The trick is to reduce the size of the images without compromising on the quality. <br /><br />If you manually optimize the images using Chrome PageSpeed Insights extension or Photoshop or any other tools, the process will take a long time. <br /><br />Speed up your website by optimizing your media Library files in a Fly and start getting more conversions. </p>
                                    <div className="header-btn">
                                        <a href="https://wordpress.org/plugins/jpeg-png-compressor/" target="_blank" className="btn-cmn btn-grn ">DOWNLOAD NOW</a>
                                        <Link to={`${isLogIn().isToken ? '/my-apis' : '/login'}`} className="btn-cmn btn-grn-outline get">GET API KEY</Link>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="header-imghldr  ">
                                    <img src={rocket} className="" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pluginfeatures">
                <div className="container">
                    <div className="heading_row type2">
                        <h2>Plugin Features</h2>
                    </div>

                    <div className="row cstm-row">
                        <div className="col-xs-6 col-sm-4 col-md-3 mob-pad0">
                            <div className="fetricn-hldr">
                                <i className="fetre-icn"></i>
                                <p>Automatically optimise images</p>
                            </div>

                        </div>
                        <div className="col-xs-6 col-sm-4 col-md-3 mob-pad0">
                            <div className="fetricn-hldr icon2">
                                <i className="fetre-icn"></i>
                                <p>Advanced background Optimization</p>
                            </div>

                        </div>
                        <div className="col-xs-6 col-sm-4 col-md-3 mob-pad0">
                            <div className="fetricn-hldr icon3">
                                <i className="fetre-icn"></i>
                                <p>Bulk Optimization</p>
                            </div>

                        </div>
                        <div className="col-xs-6 col-sm-4 col-md-3 mob-pad0">
                            <div className="fetricn-hldr icon4">
                                <i className="fetre-icn"></i>
                                <p>Thumbnail sizes</p>
                            </div>

                        </div>
                        <div className="col-xs-6 col-sm-4 col-md-3 mob-pad0">
                            <div className="fetricn-hldr icon5">
                                <i className="fetre-icn"></i>
                                <p>WPML Compatible</p>
                            </div>

                        </div>
                        <div className="col-xs-6 col-sm-4 col-md-3 mob-pad0">
                            <div className="fetricn-hldr icon6">
                                <i className="fetre-icn"></i>
                                <p>WP Retina 2x compatible</p>
                            </div>

                        </div>
                        <div className="col-xs-6 col-sm-4 col-md-3 mob-pad0">
                            <div className="fetricn-hldr icon7">
                                <i className="fetre-icn"></i>
                                <p>No file size limits</p>
                            </div>

                        </div>
                        <div className="col-xs-6 col-sm-4 col-md-3 mob-pad0">
                            <div className="fetricn-hldr icon8">
                                <i className="fetre-icn"></i>
                                <p>WooCommerce compatible</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="why-speed-sctn">
                <div className="container">
                    <div className="whyspeedmtr-hldr">
                        <div className="whyspeedmtr-lfttext">
                            <h2>Why should you speed up WordPress?</h2>
                            <p> Search engines (like Google) rank websites with fast-loading times higher than the slow-loading ones. So, if you want to improve your position in the SERPs, improving speed should be one of your priorities.<br /><br />
                                Studies have shown that site-loading <a target="_blank" href="https://www.akamai.com/us/en/about/news/press/2009-press/akamai-reveals-2-seconds-as-the-new-threshold-of-acceptability-for-ecommerce-web-page-response-times.jsp">speed of more than 2 seconds</a> usually results in about 47 percent of visitors bouncing off the website. So, to keep about half of your visitors interested, you should speed up your WordPress.</p>
                        </div>
                        <div className="whyspdmtr-imghldr"> </div>
                        <div className="clearfix"> </div>
                    </div>
                </div>
            </div>
            <div className="optmizepng-imgsctn">
                <div className="container">
                    <div className="heading_row type2">
                        <h2>Optimize your PNG and JPEG images
                            with PNG Compressor and speed up your website. </h2>
                    </div>
                    <div className="account-img-hldr">
                        <img src="images/account-img.jpg" alt="" />
                    </div>
                    <div className="account-img-hldr">
                        <div className="carousel slide" data-ride="carousel" id="quote-carousel">
                            <div className="carousel-inner text-center">
                                <Carousel
                                    indicators={false}
                                    fade={true}
                                    nextLabel={""}
                                    nextIcon={<span aria-hidden="true" className="carousel-control-next-icon"><i><FontAwesomeIcon icon={faChevronRight} /></i></span>}
                                    prevLabel={""}
                                    prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon"><i><FontAwesomeIcon icon={faChevronLeft} /></i></span>}
                                >
                                    <Carousel.Item>
                                        <div className="clientdesc">
                                            <img src="https://assets.pngcompressor.com/v1/images/Screenshot_1.png" alt="" />
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="clientdesc">
                                            <img src="https://assets.pngcompressor.com/v1/images/Screenshot_2.png" alt="" />
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="clientdesc">
                                            <img src="https://assets.pngcompressor.com/v1/images/Screenshot_3.png" alt="" />
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Faq />
            <div className="trustedcompany" >
                <div className="container">
                    <p>PNG Compressor works out of the box for these companies</p>
                    <ul className="navbar-nav nav row trusted_slide ">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>
        </>
    )
}
export default WordpressPlugin;