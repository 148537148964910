import React, { useState } from "react";
import { useDropzone } from 'react-dropzone';

const Banner = ({handleOnDrop}) => {
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: 'image/*', onDrop: acceptedFiles => handleOnDrop(acceptedFiles) });

    return (
        <div className="compress_imges">
            <div className="container">
                <div className="heading">
                    <h1>Easily Compress Images Online</h1>
                    <p>Advanced PNG, JPEG and WEBP compressor for reducing file size without quality loss and speedup your website.</p>
                </div>
                <div className={`col-lg-8 col-md-12 midcolumn ${isDragActive || isDragAccept ? "dragging" : isDragReject ? "error" : ""}`} id="dragdrop_col">
                    <div className="iconanimate">
                        <i className="icon-left"></i>
                        <i className="r_icon icon_right_one"></i>
                        <i className="r_icon icon_right_two"></i>
                        <i className="r_icon icon_right_three"></i>
                    </div>
                    <div className="uploader" id="file-upload-form">
                        <div className="upload-form">
                            {/* <input id="file-upload" type="file" name="image" accept="image/*" multiple /> */}
                            <div {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
                                <input {...getInputProps()} />
                                <label htmlFor="file-upload" id="file-drag">
                                    <div id="start">
                                        <i className="img_icon"></i>
                                        <div className="upload_title">Drag and drop your PNG, JPEG or WEBP files here! You can <b>drop 25 images</b> here and our system will automatically compress them on best quality for you. </div>
                                        <span id="file-upload-btn" className="btn btn-default chooseimg">Select Images</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner;