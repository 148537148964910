import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import successImg from "../../assets/images/payment-successful.png";
import * as Apis from "../../config/Api";
import { constants } from "../../config/constants";
import Alert from "../Common/Alert";
import moment from "moment";

export default function Thankyou() {
    const param = useParams()
    const [useData, setUserData] =useState({})
    const [purchaseData, setPurchaseData] = useState([])
    const [alertVal, setalertVal] = useState({
        showAlert: false,
        successAlert: false,
        alertMsg: ""
    })
    const toast = (alertOpen, alertType, msg) => {
        setalertVal({ ...alert, 'showAlert': alertOpen, 'successAlert': alertType, 'alertMsg': msg })
        setTimeout(() => {
            closeAlert()
        }, 3000);
    }
    const closeAlert = () => {
        setalertVal({ ...alert, 'showAlert': false, 'successAlert': false, 'alertMsg': "" })
    }
    const addon_thankyou = (id) => {
        Apis.GetApiMethod(`${constants.ADDON_THANKYOU}/${id}`).then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                setPurchaseData(res.data.purchase_data)
                setUserData(res.data)
            }
        })
    }
    useEffect(() => {
        addon_thankyou(param.id)
    }, [])
    return (
        <div className="site-wrap my-account">
            <div className="header-banner">
                <div className="container">
                    <div className="header-content">
                        <h2>Welcome, <span className="usernameShow">{useData.user_name} </span>!</h2>
                    </div>
                    <div className="wrap">
                        <div className="tab-content">
                            <div className="tab-pane active">
                                <div className="payment_success">
                                    <img src={successImg} alt="" />
                                    <div className="mt-3" style={{ color: "green" }}>
                                        <h3>Your order has been confirmed.</h3>
                                        <p>You have successfully purchased AddOn credits,<br />Your order ID <strong>
                                            {param.id}
                                        </strong> and the same credits added to your account.</p>
                                    </div>
                                </div> <br /> <br />
                                {purchaseData.length > 0 &&
                                    <div className="api-table">
                                        <table>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Images Buy </th>
                                                <th>Amount Pay</th>
                                                <th>Transaction Id</th>
                                                <th>Payment Date</th>
                                            </tr>
                                            {purchaseData.map((data, index) => {
                                                return (
                                                    <tr key={index.toString()}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.buy_images}</td>
                                                        <td>${parseFloat(data.amount_pay).toFixed(2)}</td>
                                                        <td>{data.txn_id}</td>
                                                        <td style={{ whiteSpace: 'nowrap' }}>{moment(data.created).format("DD-MM-YYYY hh:mm a")}</td>
                                                    </tr>
                                                )
                                            })}
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Alert
                showAlert={alertVal.showAlert}
                successAlert={alertVal.successAlert}
                alertMsg={alertVal.alertMsg}
            />
        </div>
    )
}