import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPencilAlt, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'
import * as Apis from "../../config/Api";
import { constants } from "../../config/constants";
import Alert from "../Common/Alert";
import Pricing from "../Home/Pricing/Pricing";
import PriceChart from "../Home/Pricing/PriceChart";
import _ from "underscore";
import AccountTab from "./AccountTab";
import AlertConfirmation from "../Common/AlertConfirmation";
import Loading from "../Common/Loading";
import moment from "moment";
import DownloadPDF from "./DownloadPDF";
import ReactDOMServer from 'react-dom/server';
import jsPDF from 'jspdf';
import { useLocation } from "react-router";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";

const MyAccount = () => {
    const location = useLocation()
    const myRef = useRef(null)
    const [allData, setallData] = useState("")
    const [purchaseData, setPurchaseData] = useState([])
    const [disableBtn, setDisableBtn] = useState(false)
    const [value, setValue] = useState({
        your_name: "",
        email: ""
    })
    const [detail, setDetail] = useState({})
    const [consumedData, setconsumedData] = useState([])
    const [apiList, setApiList] = useState([])
    const [val, setVal] = useState({
        key: "",
        domain: ""
    })
    const [domainList, setDomainList] = useState([])
    const [row, setRow] = useState({
        start: 0,
        end: 50
    })
    const [pagination, setpagination] = useState({
        page: 1,
        pageCount: 1,
        limit: 50,
    })
    const [alertMessage, setalertMessage] = useState("")
    const [showModalAlert, setShowModalAlert] = useState(false)
    const [showLoading, setshowLoading] = useState(true)
    const closeModal = () => {
        setShowModalAlert(false)
        setalertMessage("")
    }
    const [alertVal, setalertVal] = useState({
        showAlert: false,
        successAlert: false,
        alertMsg: ""
    })
    const toast = (alertOpen, alertType, msg) => {
        setalertVal({ ...alert, 'showAlert': alertOpen, 'successAlert': alertType, 'alertMsg': msg })
        setTimeout(() => {
            closeAlert()
        }, 3000);
    }
    const closeAlert = () => {
        setalertVal({ ...alert, 'showAlert': false, 'successAlert': false, 'alertMsg': "" })
    }
    const [editForm, seteditForm] = useState(false)
    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }
    const showEdit = () => {
        seteditForm(true)
    }
    const cancelEdit = () => {
        seteditForm(false)
    }
    const executeScroll = () => {
        myRef.current.scrollIntoView()
    }

    const updateAccountDetail = (body) => {
        Apis.PutApiMethod(constants.UPDATE_PROFILE, body).then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                toast(true, true, res.data.message)
                cancelEdit()
            }
        })
    }

    const updateProfile = () => {
        let data = {
            your_name: value.your_name,
            email: value.email,
        }
        updateAccountDetail(data)
    }

    const resendEmail = () => {
        Apis.GetApiMethod(constants.RESEND_EMAIL).then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                toast(true, true, res.data.message)
            }
        })
    }

    const showCancelAlert = () => {
        setShowModalAlert(true)
        setalertMessage("You want to cancel this subscription")
    }

    const okClick = () => {
        Apis.PutApiMethod(constants.CANCEL_SUBSCRIPTION, "").then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                toast(true, true, res.data.message)
                closeModal()
                window.location.reload(false)
            }
        })
    }

    const details = (val) => {
        setTimeout(() => {
            setshowLoading(false)
        }, 1000);
        setDetail(val)
        setValue({ ...value, 'your_name': val.user_name, 'email': val.email })
    }

    const addon_purchase_list = () => {
        Apis.GetApiMethod(constants.ADDON_PURCHASE_LIST).then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                setPurchaseData(res.data.recent_purchase_data)
            }
        })
    }

    const addon_thankyou = (id) => {
        Apis.GetApiMethod(`${constants.ADDON_THANKYOU}/${id}`).then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                var l = {
                    orientation: 'p',
                    unit: 'pt',
                    format: 'a4',
                    compress: true,
                    autoSize: true,
                    printHeaders: true
                }
                const doc = new jsPDF(l, '', '', '')
                const htmlToConvert = ReactDOMServer.renderToString(<DownloadPDF data={res.data} />)
                // setallData(htmlToConvert)
                doc.html(htmlToConvert, {
                    callback: function (doc) {
                        doc.save("reciept.pdf");
                    }
                });
            }
        })
    }


    const handleCopy = (index) => {
        consumedData[index]["copied"] = true
        setconsumedData([...consumedData])
        setTimeout(() => {
            consumedData[index]["copied"] = false
            setconsumedData([...consumedData])
        }, 1000);
    }

    const handleSelect = (e) => {
        setVal({ ...val, [e.target.name]: e.target.value })
    }

    const imageFilter = (body) => {
        Apis.PostApiMethod(constants.IMAGE_CONSUMED_FILTER, body).then(res => {
            let data = res.data
            if (data.image_consumed_data) {
                setconsumedData(data.image_consumed_data)
                setpagination({ ...pagination, 'pageCount': data.total_row })
            }
        })
    }

    const searchFilter = () => {
        let data = {
            "keytoken": val.key,
            "domaintoken": val.domain,
            "start_row": 0,
            "endRow": 50
        }
        imageFilter(data)
    }

    const getDomainList = () => {
        Apis.GetApiMethod(constants.DOMAIN_LIST).then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                setDomainList(res.data.domain_list)
            }
        })
    }
    const getImageConsumed = (start, end) => {
        Apis.GetApiMethod(`${constants.IMAGE_CONSUMED}/${start}/${end}`).then(res => {
            setTimeout(() => {
                setshowLoading(false)
            }, 1000);
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                let data = res.data
                if (data.image_consumed_data) {
                    setconsumedData(data.image_consumed_data)
                    setpagination({ ...pagination, 'pageCount': data.total_row })
                }
            }
        })
    }
    const handlePageClick = (data) => {
        if (val.key !== "" || val.domain !== "") {
            let data = {
                "keytoken": val.key,
                "domaintoken": val.domain,
                "start_row": 0,
                "endRow": 50
            }
            imageFilter(data)
        } else {
            setpagination({ ...pagination, 'page': data.selected + 1 })
            setRow({ start: data.selected * 50, end: (data.selected * 50) + 50 })
            getImageConsumed(data.selected * 50, (data.selected * 50) + 50)
        }
    }
    const downloadPdf = (id) => {
        addon_thankyou(id)
    }



    const handleInputChange = (e, index) => {
        apiList[index]["api_key_name"] = e.target.value
        setApiList([...apiList])
    }

    const getApiList = () => {
        Apis.GetApiMethod(constants.API_LIST).then(res => {
            setTimeout(() => {
                setshowLoading(false)
            }, 1000);
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                let newArray = []
                if (location.pathname === "/my-apis") {
                    res.data.key_list.map((opt) => {
                        newArray.push({ ...opt, "edit": false })
                    })
                } else {
                    res.data.key_list.filter((val) => val.status == "1").map((opt) => {
                        newArray.push({ ...opt, "edit": false })
                    })
                }
                setApiList(newArray)
            }
        })
    }

    const updateKeyStatus = (url, body, index) => {
        Apis.PutApiMethod(url, body).then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
                getApiList()
            } else {
                toast(true, true, res.data.message)
                getApiList()
                cancelEditName(index)
            }
        })
    }
    const handleApiSelect = (e, index) => {
        let data = {
            keytoken: apiList[index].id,
            keystatus: e.target.value
        }
        updateKeyStatus(constants.UPDATE_APIKEY, data, index)
    }

    const editName = (index) => {
        apiList[index]["edit"] = true
        setApiList([...apiList])
    }

    const updateEditName = (index) => {
        let data = {
            keytoken: apiList[index].id,
            keyname: apiList[index].api_key_name
        }
        updateKeyStatus(constants.UPDATE_API_NAME, data, index)

    }
    const cancelEditName = (index) => {
        apiList[index]["edit"] = false
        setApiList([...apiList])
    }
    const handleApiCopy = (index) => {
        apiList[index]["copied"] = true
        setApiList([...apiList])
        setTimeout(() => {
            apiList[index]["copied"] = false
            setApiList([...apiList])
        }, 1000);
    }

    const addNewKey = () => {
        setDisableBtn(true)
        Apis.PostApiMethod(constants.ADD_APIKEY, "").then(res => {
            setDisableBtn(false)
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                toast(true, true, res.data.message)
                setApiList([...apiList, res.data.addedData])
            }
        })
    }
    useEffect(() => {
        addon_purchase_list()
        getApiList()
        getDomainList()
        getImageConsumed(row.start, row.end)
    }, [detail])
    return (
        <>
            <div className="myacoount">
                <i className="myacc"></i>
                <div className="container">
                    <AccountTab
                        details={details}
                    />
                    <div className="tabcontent">
                        {showLoading ?
                            <Loading
                                showLoading={showLoading}
                            />
                            :
                            <div className="tab-pane active">
                                {location.pathname === "/my-account" &&
                                    <div className="tab-pane-inner">
                                        <div className="row">
                                            <div className="col-md-6 col-lg-8 left_form">
                                                <div className="profile_head"> <strong>Your Profile</strong> </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group"> <label>Your name:</label>
                                                            {editForm ?
                                                                <input type="text" className="form-control" maxLength={40} name="your_name" value={value.your_name} onChange={handleChange} />
                                                                :
                                                                <input
                                                                    type="text" className="form-control" name="your_name" value={value.your_name} readOnly onChange={handleChange} />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group has-success has-feedback">
                                                            <label>Email:</label>
                                                            <div className="field_control">
                                                                {editForm ?
                                                                    <input type="email" className="form-control" name="email" value={value.email} onChange={handleChange} />
                                                                    :
                                                                    <>
                                                                        <input
                                                                            type="email" className="form-control" name="email" value={value.email} readOnly onChange={handleChange} />
                                                                        <i className="new-fa" id="editrecords" onClick={showEdit}>
                                                                            <FontAwesomeIcon icon={faPencilAlt} />
                                                                        </i>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        {!editForm &&
                                                            <div className="clearfix check-mark">
                                                                {!_.isEmpty(detail) && detail.user_status == 0 ?
                                                                    <span className="infoNotverify">
                                                                        <i><FontAwesomeIcon icon={faTimes} /></i>Not Verified <button className="emailShow" onClick={resendEmail}>Resend Email</button>
                                                                    </span>
                                                                    :
                                                                    <span className="infoHide">
                                                                        <i><FontAwesomeIcon icon={faCheck} /></i>Verified
                                                                    </span>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                {editForm &&
                                                    <div className="text-center update_profile clearfix">
                                                        <button id="infoUpdates" className="btn btn-success" onClick={updateProfile}>Update</button>
                                                        <button id="infoCancel" className="btn btn-default cancel ml-3" onClick={cancelEdit}>Cancel</button>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-md-6 col-lg-4">
                                                <div className="plan">
                                                    <div className="basicplan">
                                                        <span> {!_.isEmpty(detail) && detail.plan_name}<br /><i>Current Plan</i> </span>
                                                        <span className="float-right">
                                                            {!_.isEmpty(detail) && detail.plan_name.toLowerCase() !== "free" &&
                                                                <button className="btn btn-default cancel confirmcancels cancel" onClick={showCancelAlert}>Cancel</button>
                                                            }
                                                            <button className="btn btn-success upgrade" onClick={executeScroll}>Upgrade</button>
                                                        </span>
                                                        <div className="clearfix">
                                                            <p className="big_size">{!_.isEmpty(detail) && detail.active_plan_points} <em className="smallsize big_size">Images Per Month</em>    </p>
                                                        </div>
                                                    </div>
                                                    <div className="billing_method">
                                                        <div className="billing_cycle"> <span>Billing cycle<br /><em> {!_.isEmpty(detail) && detail.renewal_plan_date}</em></span> </div>
                                                        <div className="billing_cycle">
                                                            <div className="price_table"> Price </div>
                                                            <div className="price_table"> <span>${!_.isEmpty(detail) && detail.active_plan_price.toFixed(2)}<br /><em>Per Month</em></span> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {purchaseData.length > 0 &&
                                                <div className="col-sm-12 col-md-12 latest_purchase">
                                                    <div className="profile_head">
                                                        <strong>Your latest purchase</strong>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <th>Sr No.</th>
                                                                    <th>Images Buy </th>
                                                                    <th>Amount Pay</th>
                                                                    <th>Transaction Id</th>
                                                                    <th>Payment Date</th>
                                                                    <th>Invoices</th>
                                                                </tr>
                                                                {purchaseData.map((data, index) => {
                                                                    return (
                                                                        <tr key={index.toString()}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{data.buy_images}</td>
                                                                            <td>${parseFloat(data.amount_pay).toFixed(2)}</td>
                                                                            <td>{data.txn_id}</td>
                                                                            <td style={{ whiteSpace: 'nowrap' }}>{moment(data.created).format("DD-MM-YYYY hh:mm a")}</td>
                                                                            <td>
                                                                                <span onClick={() => downloadPdf(data.order_id)} style={{ textTransform: 'none' }} className="pdf" >Download</span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {location.pathname === "/my-apis" &&
                                    <>
                                        <div className="grab_head">
                                            <strong>Grab your API key</strong>
                                            <p>Detailed information and code samples are available in the <u><Link to="/developer-api">API reference.</Link></u>
                                            </p>
                                            <p>for more information to<u>
                                                <a href="https://www.pngcompressor.com/docs/index.html#png-compressor-api"> click here</a>
                                            </u>
                                            </p>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <th>Sr No.</th>
                                                        <th style={{ width: "290px" }}>Name </th>
                                                        <th>API Key</th>
                                                        <th>Status</th>
                                                    </tr>
                                                    {apiList.map((data, index) => {
                                                        return (
                                                            <tr key={index.toString()}>
                                                                <td>{index + 1}</td>
                                                                <td style={{ whiteSpace: "nowrap" }}>
                                                                    {!data.edit ?
                                                                        <>
                                                                            <button className="button mr-1" onClick={() => editName(index)}><i><FontAwesomeIcon icon={faPencilAlt} /></i></button>
                                                                            <>{data.api_key_name || "Api key"}</>
                                                                        </>
                                                                        :
                                                                        <div className="d-flex">
                                                                            <input type="text" name="keyname" value={data.api_key_name} onChange={(e) => handleInputChange(e, index)} maxLength={25} />
                                                                            <button className="btn btn-success btn-sm mx-1" onClick={() => updateEditName(index)}>Update</button>
                                                                            <button className="btn btn-danger btn-sm" onClick={() => cancelEditName(index)}>Cancel</button>
                                                                        </div>
                                                                    }
                                                                </td>
                                                                <td className={`apiClip ${data.copied ? "copidStyle" : ""}`}>
                                                                    <CopyToClipboard text={data.key} onCopy={() => handleApiCopy(index)}>
                                                                        {data.status == 3 ?
                                                                            <span className="text-dec">{data.key}</span>
                                                                            :
                                                                            <span>{data.key}</span>
                                                                        }
                                                                    </CopyToClipboard>
                                                                </td>
                                                                <td className="pop ik my_acc">
                                                                    <select name="status" id="status_api" className="status drop select_style" onChange={(e) => handleApiSelect(e, index)}>
                                                                        <option value="1" selected={data.status == "1"}>Enable</option>
                                                                        <option value="2" selected={data.status == "2"}>Disabled</option>
                                                                        {data.status !== "1" &&
                                                                            <option value="3" selected={data.status == "3"}>Delete</option>
                                                                        }
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="text-right adapi">
                                            <button onClick={addNewKey} className={`btn btn-default buynow ${disableBtn ? "disabled" : ""}`}>
                                                <i><FontAwesomeIcon icon={faPlus} /></i> Create New API Key
                                            </button>
                                        </div>
                                    </>
                                }
                                {location.pathname === "/my-usages" &&
                                    <>
                                        {!_.isEmpty(detail) && detail.plan_name.toLowerCase() == "free" ?
                                            <div className="grab_head">
                                                <strong>This feature is not available in free plan.</strong>
                                                <p>Please upgrade your account now to start looking your API usages. </p>
                                                <p>
                                                    <a className="go_pro_btnss upgrade" onClick={executeScroll}>Upgrade Now </a>
                                                </p>
                                            </div>
                                            :
                                            <>
                                                <div className="grab_head">
                                                    <strong>Detail analysis of API usages</strong>
                                                    <p>You can filter your API usage and also you can export it.</p>
                                                    <div className="exportstyle">
                                                        <div className="download_csv my_acc">
                                                            <select className="status drop" name="key" onChange={handleSelect}>
                                                                <option value="">Select Key </option>
                                                                {apiList.map((val) => {
                                                                    return (
                                                                        <option key={val.id} value={val.id}>{val.key}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <select className="status drop" name="domain" onChange={handleSelect}>
                                                                <option value="">Select Domain </option>
                                                                {domainList.length > 0 && domainList.map((val) => {
                                                                    return (
                                                                        <option key={val.id} value={val.id}>{val.domain_name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <button className="btn btn-success update" onClick={searchFilter}>Search</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="api-table">
                                                    <div className="table-responsive">
                                                        <table className="table usage-table"> <tbody>
                                                            <tr>
                                                                <th className="usages_table">Sr.no
                                                                </th>
                                                                <th className="usages_table">Image
                                                                </th>
                                                                <th className="usages_table">Api Key
                                                                </th>
                                                                <th className="usages_table">Domain Name
                                                                </th>
                                                                <th className="usages_table">Original
                                                                </th>
                                                                <th className="usages_table">Compressed
                                                                </th>
                                                                <th className="usages_table">%
                                                                </th>
                                                            </tr>
                                                            {consumedData.length > 0 ?
                                                                <>
                                                                    {consumedData.map((data, index) => {
                                                                        return (
                                                                            <tr key={index.toString()}>
                                                                                <td className="text-center">{index + 1}</td>
                                                                                <td className="text-center clipboardC">
                                                                                    <p className="showTool" title={data.imgage_path}>{data.imgage_path.length > 35 ? `${(data.imgage_path).substring(0, 12)}......${(data.imgage_path).substring(30)}` : data.imgage_path}</p>
                                                                                </td>
                                                                                <td className={`text-center apiClip ${data.copied ? "copidStyle" : ""}`}>
                                                                                    <CopyToClipboard text={data.key} onCopy={() => handleCopy(index)}>
                                                                                        <span>{`${(data.key).substring(0, 8)}......${(data.key).substring(25)}`}</span>
                                                                                    </CopyToClipboard>
                                                                                </td>
                                                                                <td className="text-center">{data.domain_name}</td>
                                                                                <td className="text-center">
                                                                                    {data.image_size < 1000 ? `${data.image_size} Bytes`
                                                                                        : data.image_size > 1000 && data.image_size < 1000000 ? `${parseFloat(data.image_size / 1000).toFixed(2)} KB`
                                                                                            : data.image_size > 1000000 ? `${parseFloat(data.image_size / 1000000).toFixed(2)} MB` : ""
                                                                                    }
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {data.compress_size < 1000 ? `${data.compress_size} Bytes`
                                                                                        : data.compress_size > 1000 && data.compress_size < 1000000 ? `${parseFloat(data.compress_size / 1000).toFixed(2)} KB`
                                                                                            : data.compress_size > 1000000 ? `${parseFloat(data.compress_size / 1000000).toFixed(2)} MB` : ""
                                                                                    }
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {parseInt((data.image_size - data.compress_size) / data.image_size * 100)}%
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </> :
                                                                <tr>
                                                                    <td colSpan={"7"} className="noRecords"> No Record Found
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="pagination">
                                                        <ReactPaginate
                                                            previousLabel={'Prev'}
                                                            nextLabel={'Next'}
                                                            breakLabel={'...'}
                                                            breakClassName={'break-me'}
                                                            pageCount={pagination.pageCount}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={5}
                                                            onPageChange={handlePageClick}
                                                            containerClassName={'pagination pagination-rounded justify-content-end'}
                                                            subContainerClassName={'pages pagination'}
                                                            pageClassName={'paginate_button page-item'}
                                                            pageLinkClassName={'page-link'}
                                                            activeClassName={'active'}
                                                            nextClassName={`paginate_button page-item next ${pagination.pageCount > 1 ? "" : "d-none"}`}
                                                            previousClassName={`paginate_button page-item previous ${pagination.pageCount > 1 ? "" : "d-none"}`}
                                                            previousLinkClassName={'page-link mdi mdi-chevron-left'}
                                                            nextLinkClassName={'page-link mdi mdi-chevron-right'}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/* <div dangerouslySetInnerHTML={{ __html: allData }}>
            </div> */}
            <Pricing ref={myRef} />
            <PriceChart />
            <div className="trustedcompany" >
                <div className="container">
                    <p>PNG Compressor works out of the box for these companies</p>
                    <ul className="navbar-nav nav row trusted_slide ">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>

            <AlertConfirmation
                showModalAlert={showModalAlert}
                closeModal={closeModal}
                alertMessage={alertMessage}
                okClick={okClick}
            />
            <Alert
                showAlert={alertVal.showAlert}
                successAlert={alertVal.successAlert}
                alertMsg={alertVal.alertMsg}
            />
        </>
    )
}

export default MyAccount;