import React from "react";
import { Link } from "react-router-dom";
import noPage from "../../assets/images/404.svg";

const PageNotFound = () => {
    return (
        <div className="errorPage">
            <div className="container">
                <div>
                    <img src={noPage} className="img-fluid" alt="404" />
                </div>
                <div className="text-center ebuttons">
                    <div className="eroor">
                        <Link to="/"> Back to Home</Link>
                        <Link to="/login" className="errorgo">Go to Account</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;