import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { isLogIn } from "../../../config/Api";
import * as Apis from "../../../config/Api";
import { constants } from "../../../config/constants";
import _ from "underscore";
import Alert from "../../Common/Alert";
import PaypalExpressBtn from 'react-paypal-express-checkout';

const Pricing = forwardRef((props, ref) => {
    const paypalRef = useRef(null)
    const [detail, setDetail] = useState({})
    let env = 'production';
    const client = {
        sandbox: 'AemLJ09uT_qsYJjnyhtFn4cNAcfvCw3Sls2SloDktPgz5nr-wcMi1TSrUMb_FwmKbRUkVQ-2OSlLrbWa',
        production: 'ASCmmfyjLdn-brVOAKJqUfwohIvTDePLjdPgx73bQnmKHj-ZsQHeBnzYLTFRkC_B7Nwh24M_def8Epgc',
    }
    const [alertVal, setalertVal] = useState({
        showAlert: false,
        successAlert: false,
        alertMsg: ""
    })
    const toast = (alertOpen, alertType, msg) => {
        setalertVal({ ...alert, 'showAlert': alertOpen, 'successAlert': alertType, 'alertMsg': msg })
        setTimeout(() => {
            closeAlert()
        }, 3000);
    }
    const closeAlert = () => {
        setalertVal({ ...alert, 'showAlert': false, 'successAlert': false, 'alertMsg': "" })
    }
    const [plans, setplans] = useState([])
    const getplanList = () => {
        Apis.GetSubscription(constants.PLAN_LIST).then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                if (res.data.subscrptionplan) {
                    let newArray = []
                    res.data.subscrptionplan.filter((opt) => opt.subscription_name !== "free").map((data) => {
                        newArray.push(data)
                    })
                    setplans(newArray)
                }
            }
        })
    }
    const updatePlan = (body) => {
        Apis.PutApiMethod(constants.UPDATE_SUBSCRIPTION, body).then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                // console.log("res: ", res)
                toast(true, true, res.data.message)
                getAccountDetail()
                getplanList()
                setTimeout(() => {                    
                    window.location.reload(false)
                }, 1000);
            }
        })
    }

    const handleSuccess = (e, id, amt) => {
        let data = {
            subscription_pay_id: e.paymentID,
            paypal_response: e,
            st_token: id
        }
        updatePlan(data)
    }
    const getAccountDetail = () => {
        Apis.GetApiMethod(constants.ACCOUNT_DETAILS).then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                setDetail(res.data)
            }
        })
    }
    useEffect(() => {
        if(isLogIn().token){
            getAccountDetail()
        }
        getplanList()
    }, [])
    let style = {
        'tagline': false,
        'size': 'responsive',
        'label': "buynow",
        'shape': 'rect'
    };

    return (
        <div className="simplepricing" ref={ref}>
            <div className="container">
                <div className="col-md-11 midcolumn">
                    <div className="heading_row">
                        <strong>Simple Pricing</strong>
                        <p className="bigsize">All accounts start out as a <b>FREE Account</b> which comes with 1000 Credits per month.</p>
                        <p>We have pretty simple pricing and lowest price as comparision to other available resources. You can subscribe to any plan which suits to your requirements. If in the middle you consumed your number of credits then you can recharge addon credits anytime. That will never expire once your account is renewed in next month.</p>
                    </div>
                </div>
                <div className="clearfix pricing_mod lightSlider" id="upgrade_plan">
                    <div className="row">
                        {plans.length > 0 && plans.map((data, index) => {
                            return (
                                <div className="col-sm-6 col-md-3" key={index.toString()}>
                                    <div className={`pricinglist pr_icon ${data.subscription_name}`}>
                                        {!_.isEmpty(detail) &&
                                            data.subscription_name.includes(detail.plan_name.toLowerCase()) &&
                                            <div className="ribbon ribbon-top-left"><span>Current Plan</span></div>
                                        }
                                        <strong>{data.subscription_name}</strong> <i className="Icon"></i>
                                        <div className="value">{data.image_count}</div>
                                        <div className="credits">Credits per month</div>
                                        <div className="dollarvalue">
                                            ${data.price}
                                            <span className='month'>/Month</span>
                                        </div>
                                        {isLogIn().isToken ?
                                            <>
                                                <div className="paynow_btn">
                                                    <button type="submit" className="btn btn-default buynow">Buy Now</button>
                                                    <PaypalExpressBtn env={env} ref={paypalRef} style={style} client={client} currency={'USD'} total={data.price} onSuccess={(e) => handleSuccess(e, data.stid, data.price)} />
                                                </div>
                                            </>
                                            :
                                            <Link to="/login" className="btn btn-default buynow">Login to Buy</Link>
                                        }
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>

            <Alert
                showAlert={alertVal.showAlert}
                successAlert={alertVal.successAlert}
                alertMsg={alertVal.alertMsg}
            />
        </div>
    )
})

export default Pricing