import React from 'react';
import _ from "underscore";
import moment from "moment";
import logo from "../../assets/images/logo_login.png"

export default function DownloadPDF({ data }) {
    const style = {
        pdf: {
            height: "100%",
            width: "575px",
            backgroundColor: "#fff",
            color: "#000",
            margin: "10px",
            padding: "20px",
            fontSize: "10px",
            fontFamily: "Arial"
        },
        header: {
            display: "table",
            width: "100%"
        },
        header_row: {
            display: "table-row",
        },
        header_left: {
            display: "table-cell",
            whiteSpace: "nowrap",
            width: "50%"
        },
        header_right: {
            display: "table-cell",
            whiteSpace: "nowrap",
            width: "50%"
        },
        table: {
            borderCollapse: "collapse",
            width: "100%",
            border: 0,
        },
        tableTH: {
            padding: "5px 15px",
            fontSize: "10px",
            letterSpacing: "1px",
            border: "#e1e1e1 1px solid",            
        },
        address: {
            margin: "30px 0",
            display: "table-cell",
        }
    }
    return (
        <div className="pdfBloch_inner" style={style.pdf}>
            <div className="pdf_mainBlock">
                <div style={style.header}>
                    <div style={style.header_row}>
                        <div style={style.header_left}>
                            <div style={{ marginBottom: "30px" }}>
                                <img src={logo} alt="" width="100" />
                            </div>
                            <div style={style.address}>
                                <p>624, Tower - A, 6th Floor,<br />
                                    Bestech Business Towers,<br />
                                    Sector-66 Mohali,<br />
                                    Punjab INDIA</p>
                                <p style={{ marginTop: "20px" }}><a href='https://pngcompressor.com/'>https://www.pngcompressor.com</a></p>
                            </div>
                        </div>
                        <div style={style.header_right}>
                            <p style={{marginBottom: "0px"}}><strong style={{ width: "100px", display: "inline-block" }}>Transaction ID: </strong>{data.purchase_data[0].txn_id}</p>
                            <p style={{marginBottom: "0px"}}><strong style={{ width: "100px", display: "inline-block" }}>Date &amp; Time:</strong> {moment(data.purchase_data[0].created).format("Do MMM, YYYY hh:mm A")}</p>
                            <p><strong style={{ width: "100px", display: "inline-block" }}>Payment Method:</strong> PayPal</p>
                        </div>
                    </div>
                </div>

                <div style={{marginTop: "20px", whiteSpace: 'nowrap', width: "100%"}}>
                    <h2 style={{ fontSize: "14px", fontWeight: "bold" }}>Bill to</h2>
                    <p style={{ marginBottom: "20px" }}>{data.email}</p>
                    <table style={style.table} cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th style={style.tableTH}>Credits</th>
                                <th style={style.tableTH}>Order ID</th>
                                <th style={style.tableTH}>Type</th>
                                <th style={style.tableTH}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <td style={style.tableTH}>{data.purchase_data[0].buy_images}</td>
                            <td style={style.tableTH}>{data.purchase_data[0].order_id}</td>
                            <td style={style.tableTH}><span style={{ textTransform: "capitalize" }}>{data.purchase_data[0].txn_type}</span></td>
                            <th style={style.tableTH}>${parseFloat(data.purchase_data[0].amount_pay).toFixed(2)}</th>
                        </tbody>
                    </table>
                </div>
                <div style={{ textAlign: "center", whiteSpace: 'nowrap', width: "100%", borderTop: "#e1e1e1 1px solid", padding: "20px 0" }}>
                    <p>
                        Copyrights {new Date().getFullYear()} - pngcompressor.com
                    </p>
                </div>
            </div>
        </div>
    )
}