import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xs-4 col-sm-6 col-md-3">
                        <Link to="/" className="ftlogo"></Link>
                    </div>
                    <div className="col-xs-8 col-sm-6 col-md-5 newsletter">
                        <div className="copyright clearfix">
                            <p>© {new Date().getFullYear()} PNGcompressor.com</p>
                        </div>
                    </div>
                    <div className="footerlinks col-xs-12 col-sm-12 col-md-4">
                        <ul className="footernav">
                            <li><Link to="/terms-of-use">Terms to use</Link>|</li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link>|</li>
                            <li><a target="_blank" href="https://support.pngcompressor.com">Support</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer