import React from "react";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import compressImg from "../../../assets/images/compressimage.jpg";
import originalImg from "../../../assets/images/originalimage.jpg";

const Compare = () => {
    return (
        <div className="check_ex">
            <div className="container-fluid">
                <div className="heading_row">
                    <strong>Check Example</strong>
                    <p>Comparison between original image and compressed image</p>
                </div>
                <div className="cd-image-container">
                    <ReactCompareSlider
                        position={20}
                        itemOne={<ReactCompareSliderImage src={originalImg} srcSet={originalImg} alt="Image one" />}
                        itemTwo={<ReactCompareSliderImage src={compressImg} srcSet={compressImg} alt="Image two" />}
                    />
                    <div className="original_text_green blue">
                        <span className="cd-image-label" data-type="original">COMPRESS<b>File size: 143KB</b></span>
                    </div>
                    <div className="original_text_green">
                        <span className="cd-image-label" data-type="modified">Original<b>File size: 432KB</b></span>
                    </div>
                </div>
                <div className="compressionfilesize_result"> <span className="success" data-type="original" style={{ width: "67%" }}>67% file size compression</span> </div>
            </div>
        </div>
    )
}

export default Compare;