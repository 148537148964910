import React, { useState } from "react";
import loginimg from "../../../assets/images/loginimg.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import _ from "underscore";
import * as Apis from "../../../config/Api";
import { constants } from "../../../config/constants";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";

const Signup = () => {
    let history = useHistory()
    const [value, setValue] = useState({
        email: "",
        your_name: ""
    })
    const [alertVal, setalertVal] = useState({
        showAlert: false,
        successAlert: false,
        alertMsg: ""
    })
    const toast = (alertOpen, alertType, msg) => {
        setalertVal({ ...alert, 'showAlert': alertOpen, 'successAlert': alertType, 'alertMsg': msg })
        setTimeout(() => {
            closeAlert()
        }, 3000);
    }
    const closeAlert = () => {
        setalertVal({ ...alert, 'showAlert': false, 'successAlert': false, 'alertMsg': "" })
    }
    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }

    const registerFn = (body) => {
        Apis.PostApiMethod(constants.REGISTER, body).then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                if (res.data.success === true) {
                    toast(true, true, res.data.message)
                    Apis.setToken(res.data.token)
                    setValue({
                        email: "",
                        your_name: ""
                    })
                    setTimeout(() => {
                        history.push("/login")
                    }, 3000);
                }
            }
        })
    }

    const submitSignup = (e) => {
        e.preventDefault();
        let valid = true
        if (value.your_name === "") {
            toast(true, false, 'Please enter your name')
            valid = false
        } else if (value.email === "") {
            toast(true, false, 'Please enter your email')
            valid = false
        }
        else {
            valid = true
        }
        if (valid) {
            let data = { email: value.email, your_name: value.your_name }
            registerFn(data)
        }

    }
    return (
        <div className="headerbg loginform">
            <Helmet>
                <title>Create and account - PNG Compressor</title>
            </Helmet>
            <div className="login_form">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="row justify-content-center">
                                <div className="col-sm-5 col-md-6 title_forms">
                                    <strong>Welcome to <br />PNG Compressor</strong>
                                    <img src={loginimg} alt="loginimg" className="img-fluid" width="335" />
                                </div>
                                <div className="col-sm-7 col-md-6">
                                    <div className="tabbox">
                                        <ul className="nav nav-tabs justify-content-center">
                                            <li><NavLink to="/login">Login</NavLink></li>
                                            <li><NavLink to="/signup">Signup</NavLink></li>
                                            <li><NavLink to="/forgot-password" className="forgot">Forgot Password</NavLink></li>
                                        </ul>
                                        {alertVal.showAlert &&
                                            <>
                                                {alertVal.successAlert ?
                                                    <div className="alert alert-success">{alertVal.alertMsg}</div>
                                                    :
                                                    <div className="alert alert-danger errorMesg">{alertVal.alertMsg}</div>
                                                }
                                            </>
                                        }
                                        <div className="tab-content">
                                            <form onSubmit={submitSignup}>
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <div className="input-group">
                                                        <span className="input-group-addon">
                                                            <i><FontAwesomeIcon icon={faUserAlt} /></i>
                                                        </span>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            name="your_name"
                                                            value={value.your_name}
                                                            placeholder="Please enter your name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <div className="input-group">
                                                        <span className="input-group-addon">
                                                            <i><FontAwesomeIcon icon={faEnvelope} /></i>
                                                        </span>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            name="email"
                                                            value={value.email}
                                                            placeholder="Please enter your email address"
                                                        />
                                                    </div>
                                                </div>
                                                <button className="btn btn-block">Signup</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signup