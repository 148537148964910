import React, { useState } from "react";
import { Link } from "react-router-dom";
import faqIcon from "../../../assets/images/faq-icon.png";

const Faq = () => {
    const [faqList, setFaqList] = useState([
        {
            title: "Can I try PNG Compressor for free?",
            desc: `You can optimise up to 1000 images compression credits free every month. You simply need to register for a free account and enter your account API credentials in your WordPress under ${'Settings -> PNG Compressor'}.`,
            show: true
        },
        {
            title: "How do I get my API Key?",
            desc: 'You must register a PNG Compressor account and then obtain your API key from your website settings page PNG Compressor.'
        },
        {
            title: "Is there an image file size limit?",
            desc: 'There is no image file size limit you can send any size of JPG and PNG image.'
        },
        {
            title: "What happen if I uninstall the PNG Compressos plugin?",
            desc: 'All of the images you have optimised will remain optimised and won’t be changed.'
        },
        {
            title: "Can I use the same API key on all my websites?",
            desc: 'Yes, there are no limitations. You can add the same key everywhere.'
        },
        {
            title: "Can I optimize both past and new images?",
            desc: 'You can optimize all your past/current images using our “Bulk Optimization” page in your Media with a single click.'
        }
    ])

    const faqToggle = (index) => {
        let newArr = []
        faqList.map((opt)=>{
            newArr.push({...opt, 'show': false})
        })
        newArr[index].show = !faqList[index].show
        setFaqList([...newArr])
    }
    return (
        <div className="faq-sctn">
            <div className="container">
                <div className="heading-left">
                    <h2>Frequently Asked Questions</h2>
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <div className="panel-group">
                            {faqList.map((data, index) => {
                                return (
                                    <div className="panel panel-default" key={index.toString()}>
                                        <div className="panel-heading" role="tab" id="headingOne">
                                            <h4 className="panel-title mb-0">
                                                <a onClick={()=>faqToggle(index)} className={data.show ? "collapsed":""}>
                                                    {data.title}
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseOne" className={`panel-collapse collapse ${data.show ? "in":""}`} role="tabpanel" aria-labelledby="headingOne">
                                            <div className="panel-body">
                                                {data.desc}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="faq-imghldr">
                            <img src={faqIcon} className="img-fluid" alt="" />
                        </div>
                        <div className="header-btn">
                            <a href="https://wordpress.org/plugins/jpeg-png-compressor/" target="_blank" className="btn-cmn btn-grn ">DOWNLOAD NOW</a>

                            <Link to='/developer-api' className="btn-cmn btn-grn-outline get">GET API KEY</Link>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        </div>
    )
}

export default Faq;