export const constants = {
    UPLOAD: '/upload',
    LOGIN: '/login',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/send-forgotpwd-link',
    UPDATE_PASSWORD: '/update-password',
    VERIFY_EMAIL: '/verify-email',
    SEND_MAGIC_LINK: '/send-login-magiclink',
    LOGIN_MAGIC_LINK: '/login-magiclink',
    ACCOUNT_DETAILS: '/account-details',
    UPDATE_PROFILE: '/update-profile',
    RESEND_EMAIL: '/resend-emailverification',
    API_LIST: '/apikey-list',
    ADD_APIKEY: '/apikey-add',
    UPDATE_APIKEY: '/updatekey-status',
    UPDATE_API_NAME: '/updatekey-name',
    IMAGE_CONSUMED: '/image-consumed',
    IMAGE_CONSUMED_FILTER: '/image-consumed-filter',
    DOMAIN_LIST: '/domain-list',
    PLAN_LIST: '/subscription-plan',
    UPDATE_SUBSCRIPTION: '/update-subscription',
    CANCEL_SUBSCRIPTION: '/cancel-subscription',
    ADDON_THANKYOU: '/addon-thankyou-list',
    ADDON_PURCHASE_LIST: '/addon-purchase-list',
    ADDON_INSERT: '/addon-insert',
}