import React, { useEffect, useState } from "react";
import Loading from "../../Common/Loading";
import * as Apis from "../../../config/Api";
import { constants } from "../../../config/constants";
import { useHistory } from "react-router";
import Alert from "../../Common/Alert";

const MagicLogin = () => {
    let history = useHistory()
    let param = history.location.search && history.location.search.split("?key=")[1]
    const [alertVal, setalertVal] = useState({
        showAlert: false,
        successAlert: false,
        alertMsg: ""
    })
    const toast = (alertOpen, alertType, msg) => {
        setalertVal({ ...alert, 'showAlert': alertOpen, 'successAlert': alertType, 'alertMsg': msg })
        setTimeout(() => {
            closeAlert()
        }, 3000);
    }
    const closeAlert = () => {
        setalertVal({ ...alert, 'showAlert': false, 'successAlert': false, 'alertMsg': "" })
    }


    const verfyFn = () => {
        Apis.GetApiMethod2(constants.LOGIN_MAGIC_LINK, param).then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                if (res.data.success === true) {
                    Apis.setToken(param)
                }
            }
            setTimeout(() => {
                history.push("/")
            }, 3000);
        })
    }
    useEffect(() => {
        verfyFn()
    }, [])
    return (
        <div className="verify_email">
            <Alert 
                showAlert={alertVal.showAlert}
                successAlert={alertVal.successAlert}
                alertMsg={alertVal.alertMsg}
            />
            <Loading
                show={true}
            />
        </div>
    )
}

export default MagicLogin;