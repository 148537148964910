import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

const Logout = () => {
    let history = useHistory()
    useEffect(() => {
        localStorage.clear()
        setTimeout(() => {
            history.push("/")
        }, 2000);
    }, [])
    return (
        <div className="headerbg loginform">
            <div className="login_form logoutpage">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="title_form text-center logout_page">
                                <strong>Logout Successfully</strong>
                                <h3 style={{color: "#fff"}}>Thanks For using PNG Compressor.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Logout