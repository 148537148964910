import React from "react";

const Alert = ({ showAlert, successAlert, alertMsg }) => {
    return (
        <>
            {showAlert &&
                <>
                    {successAlert ?
                        <div className="floatalert alert alert-success">{alertMsg}</div>
                        :
                        <div className="floatalert alert alert-danger errorMesg">{alertMsg}</div>
                    }
                </>
            }
        </>
    )
}

export default Alert;