import React, { useEffect, useRef, useState } from "react";
import * as Apis from "../../../config/Api";
import { constants } from "../../../config/constants";
import _ from "underscore";
import { Link } from "react-router-dom";
import Pricing from "../../Home/Pricing/Pricing";
import PriceChart from "../../Home/Pricing/PriceChart";
import { Helmet } from "react-helmet";

const DeveloperApi = () => {
    const myRef = useRef(null)
    const [detail, setDetail] = useState({})
    const getAccountDetail = () => {
        Apis.GetApiMethod(constants.ACCOUNT_DETAILS).then(res => {
            if (res.error === true) {
                console.log(res.response.data.error)
            } else {
                setDetail(res.data)
            }
        })
    }
    useEffect(() => {
        if (Apis.isLogIn().token) {
            getAccountDetail()
        }
    }, [])
    return (
        <>
            <Helmet>
                <title>Developer API - Automate your PNG and JPEG compression</title>
            </Helmet>
            <div className="develop_api">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="api_description">
                                <strong>Developer API</strong>
                                <p className="bigfnt">Use our API system to compress your images automatically.</p>
                                <p>Connect your server to optimize all your website JPEG and PNG images automatically. You will get a FREE API key and 1000 credits everyone month to use for your website. Enter your name and email address below to get your key and get started. </p>
                            </div>
                            <form className="form-horizontal" action="" method="post">
                                <div className="form-group field_list row">
                                    <div className="col-md-6 pad_right">
                                        <input type="text" id="sname" value={!_.isEmpty(detail) ? detail.user_name : ""} readonly name="sname" className="form-control" placeholder="Your full name" required />

                                    </div>
                                    <div className="col-md-6">
                                        <input type="email" value={!_.isEmpty(detail) ? detail.email : ""} readonly id="semail" name="semail" className="form-control" placeholder="Your email address" required />

                                    </div>
                                </div>
                                <Link to="/my-account" className="btn btn-default get_api">Get your API</Link>
                            </form>
                        </div>
                        <div className="col-sm-6 col-md-6 develop_img">
                            <img src="https://assets.pngcompressor.com/v1/images/developer_api.png" alt="developer_api" className="img-fluid" width="613" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="getting_started">
                <div className="container">
                    <div className="api_description blk_font text-center">
                        <strong>Getting started</strong>
                        <p>Once you get your key, you can implement it on your website with API services explained in the document link below that will have all the parameters and response codes how you can implement it on your wbesite. It's very easily to use and make HTTPS requests over to our API server and all your images will be compressed automatically. Your website speed will be increased up to 65%.</p>
                        <br />
                        <p>We have a WordPress plugin to use it. Click here to download our plugin and start using it on your website. Make sure you grab your API Key first.. </p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="curl col-md-7">
                            <span>curl --user api:YOUR_API_KEY \<br /> --data-binary @unoptimized.png -i https://api.pngcompressor.com/compress</span>
                            <div className="row wrap_api">
                                <div className="col-sm-4 col-md-4"> <img src="https://assets.pngcompressor.com/v1/images/apidocimg.png" alt="apidocimg" className="img-fluid" /> </div>
                                <div className="col-sm-8 col-md-8 apidocument">
                                    <strong>API Document</strong>
                                    <p>This document will help you to see all the parameters and response codes of our all api calls.</p>
                                    <a href="https://www.pngcompressor.com/docs/index.html#png-compressor-api" target="_blank">Click here to View API Document.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container">
                    <div className="row wrap_api">
                        <div className="col-sm-6 col-md-7 nopad pngimg_icon"> <img src="https://assets.pngcompressor.com/v1/images/pngimg.png" alt="pngimg" className="img-fluid" width="480" /> </div>
                        <div className="col-sm-6 col-md-5 outer_curve">
                            <div className="freeimages">
                                <h1>Free images</h1>
                                <div clas="permonthimages">
                                    <p>First <strong>1000</strong> images<br /> per month</p>
                                </div>
                                <Link to={`${Apis.isLogIn().isToken ? '/my-account' : '/login'}`} className="tryitnow">Try it Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Pricing ref={myRef} />
            <PriceChart />
            <div className="trustedcompany" >
                <div className="container">
                    <p>PNG Compressor works out of the box for these companies</p>
                    <ul className="navbar-nav nav row trusted_slide ">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>
        </>
    )
}
export default DeveloperApi;