import React, { useEffect, useState } from "react";
import myaccount from "../../assets/images/myaccount.png";
import api from "../../assets/images/api.png";
import apiusage from "../../assets/images/apiusage.png";
import { NavLink } from "react-router-dom";
import * as Apis from "../../config/Api";
import { constants } from "../../config/constants";
import _ from "underscore";

const AccountTab = ({details}) => {    
    const [detail, setDetail] = useState({})
    const getAccountDetail = () => {
        Apis.GetApiMethod(constants.ACCOUNT_DETAILS).then(res => {
            if (res.error === true) {
                if(res.response.data.error.toLowerCase() === "wrong token" || res.response.data.error.toLowerCase() === "user does not exist"){
                    Apis.logout()
                }
            } else {
                setDetail(res.data)
                details(res.data)
            }
        })
    }
    useEffect(() => {
        getAccountDetail()
    }, [])
    return (
        <>
            <div className="heading_row b_font">
                <strong>Welcome, <span className="text-capitalize">{!_.isEmpty(detail) && (detail.user_name).split(" ")[0].substring(0, 15)}</span></strong>
                <p>Advanced PNG and JPEG compressor for reducing file size without quality loss</p>
            </div>
            <div className="tabpanel">
                <div className="heading_panel">
                    <ul className="nav">
                        <li className="active my">
                            <NavLink to="/my-account">
                                <img src={myaccount} alt="myaccount" width="27" /> My Account
                            </NavLink>
                        </li>
                        <li className="api ">
                            <NavLink to="/my-apis">
                                <img src={api} alt="api" width="26" /> API
                            </NavLink>
                        </li>
                        <li className="apiusage">
                            <NavLink to="/my-usages">
                                <img src={apiusage} alt="apiusage" width="30" /> API Usage
                            </NavLink>
                        </li>
                    </ul>
                    <div className="total_images"> Total Images: <span>{!_.isEmpty(detail) && detail.remaining_points}/{!_.isEmpty(detail) && detail.total_points} </span> </div>
                </div>
            </div>
        </>
    )
}

export default AccountTab;