import React, { useEffect, useState } from "react";
import "./Header.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import { isLogIn } from "../../config/Api";
import logoLogin from "../../assets/images/logo_login.png";

const Header = ({ headerClass, loginClass, main_class }) => {
    return (
        <header className={`main_header ${headerClass}`}>
            <nav className={`navbar navbar-default mainheader`}>
                <div className="container">
                    <div className="navbar-header">
                        {headerClass === 'loginpage' ?
                            <Link className="navbar-brand main-logo" to="/"></Link>
                            :
                            <Link className="navbar-brand" to="/">
                                <img src={logoLogin} alt="" className="img-fluid" width={100} />
                            </Link>
                        }
                    </div>
                    <div id="navbar">
                        <ul className={`nav headrow align-items-center ${loginClass}`}>
                            {isLogIn().isToken &&
                                <li className="">
                                    <Link to="/my-account">My Account</Link>
                                </li>
                            }
                            <li className="">
                                <Link to="/wordpress-plugin">WordPress Plugin</Link>
                            </li>
                            <li className="">
                                <Link to="/developer-api">Developer API</Link>
                            </li>
                            {isLogIn().isToken === false ?
                                <li className="login logbtn">
                                    <Link to="login"><FontAwesomeIcon icon={faLock} style={{ fontSize: "13px" }} /> Login</Link>
                                </li>
                                :
                                <li className="login logbtn white_login">
                                    <Link to="/logout-successfully"><FontAwesomeIcon icon={faLock} style={{ fontSize: "13px" }} /> Logout</Link>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header;