import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import Slider from 'react-rangeslider'
import { Link } from "react-router-dom";
import { isLogIn } from "../../../config/Api";
import PaypalExpressBtn from 'react-paypal-express-checkout';
import moment from "moment";
import * as Apis from "../../../config/Api";
import { constants } from "../../../config/constants";
import _ from "underscore";
import Alert from "../../Common/Alert";

const PriceChart = () => {
    const history = useHistory()
    const paypalRef = useRef(null)
    let env = 'production';
    const client = {
        sandbox: 'AemLJ09uT_qsYJjnyhtFn4cNAcfvCw3Sls2SloDktPgz5nr-wcMi1TSrUMb_FwmKbRUkVQ-2OSlLrbWa',
        production: 'ASCmmfyjLdn-brVOAKJqUfwohIvTDePLjdPgx73bQnmKHj-ZsQHeBnzYLTFRkC_B7Nwh24M_def8Epgc',
    }
    const [value, setValue] = useState(0)
    const [price, setPrice] = useState(0)
    const [alertVal, setalertVal] = useState({
        showAlert: false,
        successAlert: false,
        alertMsg: ""
    })
    const toast = (alertOpen, alertType, msg) => {
        setalertVal({ ...alert, 'showAlert': alertOpen, 'successAlert': alertType, 'alertMsg': msg })
        setTimeout(() => {
            closeAlert()
        }, 3000);
    }
    const closeAlert = () => {
        setalertVal({ ...alert, 'showAlert': false, 'successAlert': false, 'alertMsg': "" })
    }

    const handleChange = (val) => {
        setValue(val)
        if (val > 0 && val <= 10000) {
            setPrice(parseFloat(val * 0.004))
        } else if (val > 10000) {
            let final_image = val - 10000
            let first_image = parseFloat(10000 * 0.004)
            let amount = (final_image * 0.002).toFixed(2);
            var total_price = (parseFloat(amount) + parseFloat(first_image)).toFixed(2);
            setPrice(parseFloat(total_price))
        } else {
            setPrice(0)
        }
    }
    const handleInputChange = (e) => {
        if (e.target.value >= 0) {
            handleChange(e.target.value)
            setValue(e.target.value)
        }
    }

    const handleSuccess = (e) => {
        let character = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let orderId = "";
        for (var i = 0; i <= 8; i++) {
            orderId += character.charAt(Math.floor(Math.random() * character.length))
        }
        let data = {
            amount_pay: price,
            buy_images: value,
            txn_id: e.paymentID.includes("PAYID-") ? e.paymentID.split("PAYID-")[1] : e.paymentID,
            txt_type: "checkout",
            buyer_email: e.email,
            payment_date: moment(new Date()).format("YYYY-MM-DD"),
            order_id: orderId,
            paypal_response: e
        }
        insertAddon(data, orderId)
    }
    const insertAddon = (body, orderId) => {
        Apis.PostApiMethod(constants.ADDON_INSERT, body).then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                history.push(`/thankyou/${orderId}`)
            }
        })
    }
    let style = {
        'tagline': false,
        'size': 'responsive',
        'label': "buynow",
        'shape': 'rect'
    };
    return (
        <div className="price_chart">
            <div className="container">
                <div className="pricing_hedaing">
                    <div className="orimg"> <span>Or</span> </div>
                    <p>CHOOSE YOUR PRICE CHART</p>
                </div>
                <div className="price-inner per-unit clearfix">
                    <div className="row flex-row-reverse">
                        <div className="col-sm-5 col-md-6 pricewrap">
                            <div className="price-calc">
                                <div className="pricing-api-calc">
                                    <h4>How many Images you wanted to compress?</h4>
                                    <div className="calculator-outer">
                                        <form>
                                            <div className="rangebar slide">
                                                {/* <h4>Compressed images: <span className="com_img">1</span></h4> */}
                                                {/* <input type="range" name="amountRange" min="0" max="200000" value="0" data-rangeslider /> */}
                                                <Slider
                                                    min={0}
                                                    max={200000}
                                                    value={value}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                    <div className="price_outer_box">
                                        <span className="quantity">
                                            <strong><span className="change_price">Total Credits </span></strong>
                                            <input className="input_value manual" type="number" onChange={handleInputChange} name="amountInput" min="0" max="200000" value={value} />
                                        </span>
                                        <p className="total-price">
                                            <strong><span className="change_price">Total Price </span></strong>
                                            <em className="price_t">${price.toFixed(2)}</em>
                                        </p>
                                        <div className="total">
                                            {isLogIn().isToken ?
                                                <div className="paynow_btn">
                                                    <button className="send_paypal" disabled="true" width="280" id="paypal_load"></button>
                                                    {value !== 0 &&
                                                        <PaypalExpressBtn env={env} ref={paypalRef} style={style} client={client} currency={'USD'} total={price.toFixed(2)} onSuccess={(e) => handleSuccess(e)} />
                                                    }
                                                </div>
                                                :
                                                <Link to="/login" className="btn btn-default buynow">Login to Buy</Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-7 col-md-6">
                            <div className="pricing-api">
                                <div className="pricing-api-inner">
                                    <h3><span className="description">Images uploaded with API</span><span className="price">Price per unit</span></h3>
                                    <ul className="nav">
                                        <li> <span className="imgcompression"></span> <span className="description">First <strong>1000 images</strong> per month</span> <span className="price_data"><strong className="free_data">free</strong></span> </li>
                                        <li> <span className="imgcompression"></span> <span className="description">Next <strong>9 000</strong> image compressions</span> <span className="price_data"><strong>$0.004</strong> per image</span> </li>
                                        <li> <span className="imgcompression"></span> <span className="description">After <strong>10 000</strong> image compressions</span> <span className="price_data"><strong>$0.002</strong> per image</span> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Alert
                    showAlert={alertVal.showAlert}
                    successAlert={alertVal.successAlert}
                    alertMsg={alertVal.alertMsg}
                />
            </div>
        </div>
    )
}

export default PriceChart