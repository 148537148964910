import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			setTimeout(() => {				
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			}, 10);
		}
	}

	render() {
		return <React.Fragment />
	}
}

export default withRouter(ScrollToTop)