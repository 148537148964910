import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faShareAlt, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import DropboxSaver from "react-dropbox-saver"

const Result = ({ imageData, totalPercentage, download, downloadAll, enableBtn, executeScroll, files }) => {
    const onSuccess = (file) => {
    }
    const onProgress = (val) => {
    }
    const onCancel = () => { }
    const Onerror = (err) => {
        console.log("err: ", err)
    }
    return (
        <>
            {imageData.length > 0 &&
                <div className="maincompression_view">
                    <div className="container">
                        <div className="col-lg-8 col-md-11 midcolumn">
                            <div id="response" className="result table-responsive">
                                <table className="table" id='table_imges'>
                                    <thead>
                                        <tr>
                                            <th scope="col">File Name</th>
                                            <th className="heading_index" scope="col">Original</th>
                                            <th className="heading_index" scope="col">Status </th>
                                            <th className="heading_index" scope="col">Compressed</th>
                                            <th className="heading_index" scope="col">Download</th>
                                            <th className="heading_index" scope="col">%</th>
                                        </tr>
                                    </thead>
                                    <tbody id="body">
                                        {imageData.map((data, index) => {
                                            return (
                                                <tr key={index.toString()}>
                                                    <td title={data.file_name}>{data.file_name.substring(0, 20)}</td>
                                                    <td className="heading_index">
                                                        {parseFloat(data.original_size) < 1000 ? `${parseFloat(data.original_size)} Bytes`
                                                            : parseFloat(data.original_size) > 1000 && parseFloat(data.original_size) < 1000000 ? `${parseFloat(parseFloat(data.original_size) / 1000).toFixed(2)} KB`
                                                                : parseFloat(data.original_size) > 1000000 ? `${parseFloat(parseFloat(data.original_size) / 1000000).toFixed(2)} MB` : ""
                                                        }
                                                    </td>
                                                    <td className="heading_index" style={{ width: "150px" }}>
                                                        {data.success === false ?
                                                            <p style={{ color: "red", textTransform: "capitalize" }}><small>{data.message}</small></p>
                                                            :
                                                            <div className="progress-bar-current" style={{ backgroundColor: data.over || data.oversize ? "#ff0000" : "" }}>
                                                                <span className="bar_status status_1">{data.oversize ? "File size too large" : data.over ? "Too many files" : data.success ? "Completed" : "Compressing"}</span>
                                                                <div className={`heading_index progress ${data.success && "animation_stop"}`} id="file-progress_1" value="0" style={{ width: data.over || data.oversize || data.success === false ? "0%" : data.success ? "100%" : "50%" }}></div>
                                                            </div>
                                                        }
                                                    </td>
                                                    <td className="heading_index compress_size1">
                                                        {data.compress_size &&
                                                            <>
                                                                {parseFloat(data.compress_size) < 1000 ? `${parseFloat(data.compress_size)} Bytes`
                                                                    : parseFloat(data.compress_size) > 1000 && parseFloat(data.compress_size) < 1000000 ? `${parseFloat(parseFloat(data.compress_size) / 1000).toFixed(2)} KB`
                                                                        : parseFloat(data.compress_size) > 1000000 ? `${parseFloat(parseFloat(data.compress_size) / 1000000).toFixed(2)} MB` : ""
                                                                }
                                                            </>
                                                        }
                                                    </td>
                                                    <td className="heading_index">
                                                        {data.oversize ?
                                                            null :
                                                            <>
                                                                {data.over ?
                                                                    <p>Optimize now?</p>
                                                                    :
                                                                    data.compress_percentage &&
                                                                    <a className="download_btn" download onClick={() => download(data.compress_path, data.file_name)}>Download</a>
                                                                }
                                                            </>
                                                        }
                                                    </td>
                                                    <td className=" heading_index percentages_1">
                                                        {data.oversize ?
                                                            null :
                                                            <>
                                                                {data.over ?
                                                                    <a onClick={executeScroll}>Update <br /> Plan</a>
                                                                    :
                                                                    data.compress_percentage && data.compress_percentage
                                                                }
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="total_cmpress" id="messages">
                                    Total Compress: {" "}
                                    <span className='total_com'>{totalPercentage}</span>%
                                </div>
                            </div>
                            <div className="download_image">
                                <span className="download_outer">
                                    <span className="share">
                                        <FontAwesomeIcon icon={faShareAlt} /> Share it.</span>
                                    <span className="download">
                                        <a href="http://www.facebook.com/sharer.php?u=https://www.pngcompressor.com/" target="_blank" className="facebook fb-share down_text btn hover_button ">
                                            <FontAwesomeIcon icon={faFacebookF} />
                                        </a>
                                    </span>
                                    <span className="download">
                                        <a href="https://twitter.com/intent/tweet?url=https://www.pngcompressor.com/" className="twitter fb-share down_text btn hover_button ">
                                            <FontAwesomeIcon icon={faTwitter} />
                                        </a>
                                    </span>
                                    <span className="download">
                                        <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.pngcompressor.com/" target="_blank" className="facebook fb-share down_text btn hover_button ">
                                            <FontAwesomeIcon icon={faLinkedinIn} />
                                        </a>
                                    </span>
                                    <span className="download">
                                        <a href="mailto:admin@pngcompressor.com" noopener noreferrer className="mail fb-share down_text btn hover_button ">
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </a>
                                    </span>
                                </span>
                                <span className="download_options">
                                    <span id="container" className="btn drop save_to_dropbox">
                                        {files.length > 0 &&
                                            <DropboxSaver
                                                appKey={'p39e0lkw7elqc5e'}
                                                success={files => onSuccess(files)}
                                                cancel={() => onCancel()}
                                                progress={(progress) => onProgress(progress)}
                                                error={(err) => Onerror(err)}
                                                files={files}
                                            >
                                                <em className={`dropbox-dropin-btn dropbox-dropin-default dropbox-saver ${enableBtn ? "" : "disable_download"}`}><span className="dropin-btn-status">
                                                </span> Save to Dropbox</em>
                                            </DropboxSaver >
                                        }
                                    </span>
                                    <span className="download">
                                        <button className={`down_text btn hover_button downloadAll_btn ${enableBtn ? "" : "disable_download"}`} onClick={downloadAll}>
                                            <FontAwesomeIcon icon={faCloudDownloadAlt} /> Download All
                                        </button>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Result;