import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
export default function Terms() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])
    return (
        <div className="container">
            <Helmet>
                <title>PNG | Compressor</title>
            </Helmet>
            <div className="termPage">
                <h2>Terms to Use </h2>
                <p>Please carefully read, review and be sure you understand our Terms of Use prior to use this website web and api services (as Service).
                    <br /><br /> By accessing this web site, you are agreeing to be bound by the website Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trade mark law.<br /><br /> This Agreement is between PNG Compressor and you, or any juridical entity that you represent (“you”). If you enter into this Agreement on behalf of another juridical entity, you represent and warrant that you have authority to bind such entity to this Agreement.<br /><br /> You expressly understand and agree that PNG Compressor shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if PNG Compressor has been advised of the possibility of such damages), resulting from your use of the Service or third-party products that access data via the Service.<br /><br />
                    <strong>1. Your content</strong><br /> You retain the rights to content you submit to the Service. You grant PNG Compressor web and api services the right to temporarily store and modify your content insofar as necessary to provide the Service to you. Your uploaded content (via web or API) will be stored for a maximum of 48 hours. After that content will be automatically deleted from our servers.
                    <br /><br />
                    <strong>2. Usage Limits</strong><br />
                    You acknowledge and agree that PNG Compressor imposes a limit on the size and the number of requests you may send to the Service provider. PNG Compressor may change such limits (File size upload, and number of files each request via Web and API) at any time without any notifications. <br /><br />
                    <strong>3. Subscription and Addon Payments</strong><br />
                    By purchasing a subscription or Addon credits you agree to pay PNG Compressor the listed price. All the payments will be charged immediately after purchase.<br /><br /> By purchasing a paid subscription you agree to pay PNG Compressor the applicable fees for your usage of the Service. Subscriptions will either have a yearly fixed price. For all the Addon credits you purchased are non refundable and non transferable from one account to another account. All the payments on Subscription and AddOn are processed by our payment partner (PayPal). You can download your invoice of the payments from my account area.<br /><br /> Our listed prices are in U.S. dollars (USD) and all the payments are not refundable. <br /><br />
                    <strong>4. Account Deletion</strong><br /> Once you contact us about the account removal, we will schedule it and remove it no sooner than within next 7 days. All of your Account Data and all uploaded Content will be deleted from the Service upon deletion. You may request that anytime you want.<br /><br />
                    <strong>5. Service Cancellation</strong><br /> &nbsp;&nbsp;&nbsp;&nbsp;A) If you cancel the Service before the end of your current paid up year, your cancellation will take effect immediately and you will not be charged again.<br /> &nbsp;&nbsp;&nbsp;&nbsp;B) If you cancel your account you will be billed for any overage usage you had in a current paid up year. <br /><br />
                    <strong>6. PNG Compressor API</strong><br /> When you use the PNG Compressor API (Application Programming Interface) you can optimize your images in two ways - by providing a URL of the image you want to optimize or by uploading an image file directly to our API. Either way, access to those images is available only to you – you have your API credentials. We do not store images optimized by PNG Compressor API – they are available on our servers for only few hours only so you (and only you) can download them and use them in any way you want.<br /><br /> <strong>7. Data processing operations</strong><br />
                    All the Images uploaded to the Service are temporarily stored, optimized and deleted within 48 hours. A log entry contains information such as browser type, IP address, any API key, date, time, and fingerprint of the transferred file. Log entries are deleted within 60 days. If you are a PRO member then you will have the access of your API usage you can export your API logs to a local storage file in CSV (comma separated value) format. <br /><br /> The username and email address you provide to sign up or make a purchase are stored in your account. All purchases and invoices are recorded in your account.<br /><br /> Payments are handled by PNG Compressor’s payment provider (PayPal). Personal data regarding a payment is stored only with the payment provider and if needed can be processed and stored outside of Europe and the US. For PayPal purchases PNG Compressor stores the purchase transaction number. PayPal shares your name, physical address and email address with PNG Compressor. That we use to generate invoice for you.<br /><br /> </p>
                <h2>Other General Conditions</h2>
                <p> Your use of the Service is at your sole risk. The service is provided on an "as is" and "as available" basis.<br /><br /> You understand that PNG Compressor uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service.<br /><br /> We reserve the right to refuse access to the Service to anyone for any reason at any time.<br /><br /> You must not change, modify, adapt or alter the Service or change, modify or alter another website so as to falsely imply that it is associated with the Service.<br /><br /> You must not interfere or disrupt the Service or servers or networks connected to the Service, including by transmitting any worms, viruses, spyware, malware or any other content of a destructive or disruptive nature. <br /><br /> You may not inject content or code or otherwise alter or interfere with the way any pngcompressor.com page is rendered or displayed in a user's browser or device.<br /><br /> You may not upload violent or discriminatory content, or content which is in any way unlawful, infringing, or hateful, to and/or via the Service.<br /><br /> We may, but have no obligation to, remove Content and Accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, or otherwise objectionable or violates any party's intellectual property or these Terms of Service.<br /><br /> This Terms Page was last modified on: <b>Aug 22, 2018</b>
                </p>
            </div>
        </div>
    )
}