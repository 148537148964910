import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import client1 from "../../../assets/images/client1.jpg";
import client2 from "../../../assets/images/client2.jpg";
import client3 from "../../../assets/images/client3.jpg";
import compressorimg from "../../../assets/images/compressorimg.png";

const Testimonial = React.forwardRef((props, ref) => {
    const slider = useRef(null)
    const slides = [
        { image: client1, id: 0 },
        { image: client2, id: 1 },
        { image: client3, id: 2 },
    ]
    const [selectedId, setselectedId] = useState(0)

    const changeSlide = (id) => {
        slider.current.slickGoTo(id)
        setselectedId(id)
    }
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        centerMode: false,
        swipeToSlide: true,
        accessibility: true,
        focusOnSelect: false,
        beforeChange: (current, next) => setselectedId(next),
        afterChange: current => setselectedId(current),
    }
    return (
        <>
            <div className="customer_saying py_padding">
                <i className="bgiconlayer"></i>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="heading_row">
                                <strong>What our customer are saying</strong>
                            </div>
                            <div className="testimonial_slider" id="quote-carousel">
                                <ol className="carousel-indicators">
                                    {slides.map((data, index) => {
                                        return (
                                            <li className={data.id === selectedId && "active"} key={index.toString()} onClick={() => changeSlide(data.id)}>
                                                <img className="img-fluid " src={data.image} alt="" />
                                            </li>
                                        )
                                    })}
                                </ol>
                                <Slider ref={slider} {...settings}>
                                    <div className="item">
                                        <div className="clientdesc text-center">
                                            <p>My Website getting very low speed, I am getting a lot of complaints about this issue. Since I started using PNG Compressor my website become 80% faster than it was before. Thanks PNG Compressor team.</p>
                                            <small>John</small>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="clientdesc text-center">
                                            <p>I am running a print media company so useally when I would like to upload my portfolio items it comes in heavy size CMYK format as color scheme. I tried many other image compressros but thanks to PNG Compressor which change all my CMYK media to very light weight JPG files and my website speed is also increased significantly.</p>
                                            <small>Adam</small>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="clientdesc text-center">
                                            <p>I have tried Tiny PNG and Krakon before I switched to PNG Compressor the reason behind of my switch is prices. PNG Compressors providing much better results at 10 time lower costs. So I would like to recommend anyone who would like to compress their website images.</p>
                                            <small>Kelly</small>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wordpressplugin">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-5 col-md-5 wordpressinfo">
                            <strong>Download Free <b>WordPress Plugin</b></strong>
                            <p>Speed up your website by optimizing your media library files in a fly and start getting more conversions</p>
                            <a href="https://wordpress.org/plugins/jpeg-png-compressor/" target="_blank" className="downloadnow">Download Now</a>
                        </div>
                        <div className="col-sm-7 col-md-7 png_img"> <img src={compressorimg} alt="" className="img-fluid" width="739" /> </div>
                    </div>
                </div>
            </div>
        </>
    )
})

export default Testimonial;