import axios from "axios";
import { Route, Redirect } from "react-router-dom";
import _ from "underscore";

export const Api = axios.create({
    // baseURL: `https://pngapi.webexpertsonline.org/web/v1.2`,
    baseURL: `https://api.pngcompressor.com/web/v1.0`,
});

export const isLogIn = () => {
    if (localStorage.getItem("TOKEN")) {
        return { isToken: true, token: localStorage.getItem("TOKEN") };
    } else {
        return { isToken: false, token: "" };
    }
};

export const logout = () => {
    localStorage.clear()
    window.location.reload(false)
}

export const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            localStorage.getItem("TOKEN") ? (
                <Redirect to="/" />
            ) : (
                <Component {...props} />
            )
        }
    />
);
export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            localStorage.getItem("TOKEN") ? (
                <Component {...props} />
            ) : (
                <Redirect to="/" />
            )
        }
    />
);

export const setToken = (token) => {
    localStorage["TOKEN"] = token
}

const headers = () => {
    if (isLogIn().token) {
        return {
            headers: {
                "authorization": `Bearer ${isLogIn().token}`,
                "x-forwarded-for": "122.160.61.100"
            }
        }
    } else {
        return {
            headers: {
                "x-forwarded-for": "122.160.61.100"
            }
        }
    }
}
const headers2 = (token) => {
    return {
        headers: {
            "authorization": `Bearer ${token}`,
            "x-forwarded-for": "122.160.61.100"
        },
    }
}

//Handle API response
function handleResponse(response) {
    if (response.status === 200) {
        return response
    }
    else {
        return { response: { data: { error: response.data.message } }, error: true }
    }
}

//APIs
export const GetSubscription = (url) => {
    return Api.get(url, {headers: {"x-forwarded-for": "122.160.61.100"}}).then(handleResponse).catch((err) => {
        return { error: true, response: { data: { error: err.response.data.message } }, res: err.response }
    })
}
export const GetApiMethod = (url) => {
    return Api.get(url, headers()).then(handleResponse).catch((err) => {
        return { error: true, response: { data: { error: err.response.data.message } }, res: err.response }
    })
}
export const GetApiMethod2 = (url, token) => {
    return Api.get(url, headers2(token)).then(handleResponse).catch((err) => {
        return { error: true, response: { data: { error: err.response.data.message } }, res: err.response }
    })
}
export const PostApiMethod = async (url, body) => {
    return Api.post(url, body, headers()).then(handleResponse).catch((err) => {
        return { error: true, response: { data: { error: err.response.data.message } }, res: err.response }
    })
}
export const PutApiMethod = async (url, body) => {
    return Api.put(url, body, headers()).then(handleResponse).catch((err) => {
        return { error: true, response: { data: { error: err.response.data.message } }, res: err.response }
    })
}
export const PutApiMethod2 = async (url, body, token) => {
    return Api.put(url, body, headers2(token)).then(handleResponse).catch((err) => {
        return { error: true, response: { data: { error: err.response.data.message } }, res: err.response }
    })
}