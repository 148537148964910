import React from "react";
import speedupimg from "../../../assets/images/speedupimg.svg";

const About = () => {
    return (
        <>
            <div className="pngcompressor">
                <div className="container">
                    <div className="col-md-11 midcolumn">
                        <div className="heading_row">
                            <strong>What is PNG Compressor?</strong>
                            <p>It is a free online tool provided by www.pngcompressor.com for compressing JPG and PNG images without losing image quality. We use the latest image compression methods and intelligent algorithms to lower your image file size. This is a FREE tool for web interface you can compress your images at no cost.</p>
                        </div>
                        <div className="typecompression faicon">
                            <div className="clearfix evencol">
                                <div className="col-sm-11 col-md-9 boxlayout col-md-offset-1">
                                    <h4 className=" media-heading">JPEG Compressor</h4>
                                    <p>JPEG is a popular file/compression format used for storing digital images. Most JPEG files can be optimized further without losing any visual image quality. Upload and compress JPEG files above to improve page load times and save your bandwidth costs.</p>
                                </div>
                                <i className="jpgcloudicon"></i> <i className="cloud_style"></i>
                            </div>
                            <div className="clearfix oddcol">
                                <i className="pngcloudicon"></i>
                                <div className="col-sm-11 col-md-9 boxlayout">
                                    <h4 className=" media-heading">PNG Compressor</h4>
                                    <p>PNG compresses images while keeping original data (lossless) As a result, file size can be bigger. Our compressor applies lossy algorithms, strip unwanted data, converts 24-bit color index to 8-bit. The Result is a drastically lower PNG image size with the same image quality.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="speedup white_txt">
                <div className="weblayer"></div>
                <div className="container">
                    <div className="row midcolumn">
                        <div className="col-md-11">
                            <div className="row">
                                <div className="webcontent col-sm-9 col-md-9 padding">
                                    <strong>Speed up your website </strong>
                                    <p>If you are a webmaster, you want your pages to load faster to provide the best user experience. Optimizing your images will lead to faster page loading, save bandwidth, and help your website rank higher in search results.</p>
                                    <p>Higher speed web pages will also help your adword campaign to generate great conversion rates over your advertising. So its not to just speed up website it also helps you to get more business from your website.</p>
                                </div>
                                <div className="col-sm-3 col-md-3 rocketimg">
                                    <img src={speedupimg} alt="speedupimg" width="181" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About