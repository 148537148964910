import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

export default function AlertConfirmation({ showModalAlert, closeModal, alertMessage, okClick }) {
    return (
        <Modal
            show={showModalAlert}
            onHide={closeModal}
            size="md"
        >
            <ModalBody>
                <div className="alert_confirmation text-center">
                    <h2 style={{ fontSize: "40px", color: "red" }}><FontAwesomeIcon icon={faExclamationTriangle} /></h2>
                    <h2>Are you sure?</h2>
                    <h4>{alertMessage}</h4>
                    <div className="d-flex justify-content-center mt-4">
                        <button className="btn btn-danger btn-md" onClick={closeModal}>No</button>
                        <button className="btn btn-success btn-md ml-3" onClick={okClick}>Yes</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}