import React, { useState } from "react";
import loginimg from "../../../assets/images/loginimg.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'
import _ from "underscore";
import * as Apis from "../../../config/Api";
import { constants } from "../../../config/constants";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import { Helmet } from "react-helmet";

const Login = () => {
    let history = useHistory()
    const [value, setValue] = useState({
        email: "",
        password: "",
        magic_email: ""
    })
    const [showModal, setshowModal] = useState(false)
    const [alertVal, setalertVal] = useState({
        showAlert: false,
        showAlert2: false,
        successAlert: false,
        alertMsg: ""
    })
    const closeModal = () => {
        setshowModal(false)
    }
    const toast = (alertOpen, alertOpen2, alertType, msg) => {
        setalertVal({ ...alert, 'showAlert': alertOpen, 'showAlert2': alertOpen2, 'successAlert': alertType, 'alertMsg': msg })
        setTimeout(() => {
            closeAlert()
        }, 3000);
    }
    const closeAlert = () => {
        setalertVal({ ...alert, 'showAlert': false, 'showAlert2': false, 'successAlert': false, 'alertMsg': "" })
    }
    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }

    const loginFn = (type, body) => {
        Apis.PostApiMethod(type, body).then(res => {
            if (res.error === true) {
                toast(true, false, false, res.response.data.error)
            } else {
                if (res.data.success === true) {
                    if (type === constants.LOGIN) {
                        Apis.setToken(res.data.token)
                        history.push("/my-account")
                    } else {
                        toast(false, true, true, res.data.message)
                        setTimeout(() => {
                            history.push("/")
                        }, 3000);
                    }
                }
            }
        })
    }

    const submitLogin = (e) => {
        e.preventDefault();
        let valid = true
        _.each(value, (val, key) => {
            if (val === "" && key !== "magic_email") {
                valid = false
                toast(true, false, false, `Please enter your ${key}`)
            }
            else {
                valid = true
            }
        })
        if (valid) {
            let data = { email: value.email, password: value.password }
            loginFn(constants.LOGIN, data)
        }
    }
    const submitMagicLogin = (e) => {
        e.preventDefault();
        if (value.magic_email === "") {
            toast(false, true, false, 'Please enter your email')
        }
        else {
            let data = { email: value.magic_email }
            loginFn(constants.SEND_MAGIC_LINK, data)
        }
    }


    return (
        <div className="headerbg loginform">
            <Helmet>
                <title>PNG Compressor</title>
            </Helmet>
            <div className="login_form">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="row justify-content-center">
                                <div className="col-sm-5 col-md-6 title_forms">
                                    <strong>Welcome to <br />PNG Compressor</strong>
                                    <img src={loginimg} alt="loginimg" className="img-fluid" width="335" />
                                </div>
                                <div className="col-sm-7 col-md-6">
                                    <div className="tabbox">
                                        <ul className="nav nav-tabs justify-content-center">
                                            <li><NavLink to="/login">Login</NavLink></li>
                                            <li><NavLink to="/signup">Signup</NavLink></li>
                                            <li><NavLink to="/forgot-password" className="forgot">Forgot Password</NavLink></li>
                                        </ul>
                                        {alertVal.showAlert &&
                                            <>
                                                {alertVal.successAlert ?
                                                    <div className="alert alert-success">{alertVal.alertMsg}</div>
                                                    :
                                                    <div className="alert alert-danger errorMesg">{alertVal.alertMsg}</div>
                                                }
                                            </>
                                        }
                                        <div className="tab-content">
                                            <form onSubmit={submitLogin}>
                                                <div className="form-group">
                                                    <label >Email</label>
                                                    <div className="input-group">
                                                        <span className="input-group-addon">
                                                            <i><FontAwesomeIcon icon={faEnvelope} /></i>
                                                        </span>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            onChange={handleChange} name="email"
                                                            value={value.email}
                                                            placeholder="Please enter your email address"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label >Password</label>
                                                    <div className="input-group">
                                                        <span className="input-group-addon">
                                                            <i><FontAwesomeIcon icon={faLock} /></i>
                                                        </span>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            onChange={handleChange} name="password"
                                                            value={value.password}
                                                            placeholder="*******"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group chkbox">
                                                    <label className="labelbox">
                                                        <input type="checkbox" id="rememberMe" name="rememberMe" /> Remember me
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <button className="btn btn-block">Login</button>
                                            </form>
                                            <span className="or">OR</span>
                                            <div className="magic_links">
                                                <button on onClick={() => setshowModal(true)}><span>Login with Magic Link</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showModal}
                onHide={closeModal}
                size="md"
                className="magicbox"
            >
                <ModalHeader className="justify-content-center">
                    <h4 className="modal-title login_magic">Login with Magic Link</h4>
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={submitMagicLogin}>
                        {alertVal.showAlert2 &&
                            <>
                                {alertVal.successAlert ?
                                    <div className="alert alert-success">{alertVal.alertMsg}</div>
                                    :
                                    <div className="alert alert-danger errorMesg">{alertVal.alertMsg}</div>
                                }
                            </>
                        }
                        <div className="tab-content">
                            <div className="form-group">
                                <label >Email</label>
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        <i><FontAwesomeIcon icon={faEnvelope} /></i>
                                    </span>
                                    <input
                                        type="email"
                                        className="form-control"
                                        onChange={handleChange} name="magic_email"
                                        value={value.magic_email}
                                        placeholder="Please enter your email address"
                                    />
                                </div>
                            </div>
                            <div className="text-center">
                                <button className="btn loginbtnmagic" id="magic_links">Login</button>
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default Login