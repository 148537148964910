import React, { useState } from "react";
import loginimg from "../../../assets/images/loginimg.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLock, faUser, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import _ from "underscore";
import * as Apis from "../../../config/Api";
import { constants } from "../../../config/constants";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
    let history = useHistory()
    const [value, setValue] = useState({
        email: "",
    })
    const [alertVal, setalertVal] = useState({
        showAlert: false,
        successAlert: false,
        alertMsg: ""
    })
    const toast = (alertOpen, alertType, msg) => {
        setalertVal({ ...alert, 'showAlert': alertOpen, 'successAlert': alertType, 'alertMsg': msg })
        setTimeout(() => {
            closeAlert()
        }, 3000);
    }
    const closeAlert = () => {
        setalertVal({ ...alert, 'showAlert': false, 'successAlert': false, 'alertMsg': "" })
    }
    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }

    const submitFn = (body) => {
        Apis.PostApiMethod(constants.FORGOT_PASSWORD, body).then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                if (res.data.success === true) {
                    toast(true, true, res.data.message)
                }
            }
        })
    }

    const submitForgotPassword = (e) => {
        e.preventDefault();
        let valid = true
        _.each(value, (val, key) => {
            if (val === "") {
                toast(true, true, `Please enter your ${key}`)
                valid = false
            }
            else {
                valid = true
            }
        })
        if (valid) {
            let data = { email: value.email }
            submitFn(data)
        }

    }
    return (
        <div className="headerbg loginform">            
            <Helmet>
                <title>Reset your password - PNG Compressor</title>
            </Helmet>
            <div className="login_form">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="row justify-content-center">
                                <div className="col-sm-5 col-md-6 title_forms">
                                    <strong>Welcome to <br />PNG Compressor</strong>
                                    <img src={loginimg} alt="loginimg" className="img-fluid" width="335" />
                                </div>
                                <div className="col-sm-7 col-md-6">
                                    <div className="tabbox">
                                        <ul className="nav nav-tabs justify-content-center">
                                            <li><NavLink to="/login">Login</NavLink></li>
                                            <li><NavLink to="/signup">Signup</NavLink></li>
                                            <li><NavLink to="/forgot-password" className="forgot">Forgot Password</NavLink></li>
                                        </ul>
                                        {alertVal.showAlert &&
                                            <>
                                                {alertVal.successAlert ?
                                                    <div className="alert alert-success">{alertVal.alertMsg}</div>
                                                    :
                                                    <div className="alert alert-danger errorMesg">{alertVal.alertMsg}</div>
                                                }
                                            </>
                                        }
                                        <div className="tab-content">
                                            <form onSubmit={submitForgotPassword}>
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <div className="input-group">
                                                        <span className="input-group-addon">
                                                            <i><FontAwesomeIcon icon={faEnvelope} /></i>
                                                        </span>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            name="email"
                                                            value={value.email}
                                                            placeholder="Please enter your email address"
                                                        />
                                                    </div>
                                                </div>
                                                <button className="btn btn-block">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword