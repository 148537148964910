import React, { useCallback, useEffect, useRef, useState } from "react";
import Banner from "./Banner/Banner";
import Result from "./Banner/Result/Result";
import * as Apis from "../../config/Api";
import { constants } from "../../config/constants";
import "./Home.css";
import Compare from "./Compare/Compare";
import About from "./About/About";
import _ from "underscore";
import { saveAs } from 'file-saver';
import { generateZipFromCloud } from "./Banner/Result/ExportZip";
import Testimonial from "./Testimonials/Testimonials";
import Pricing from "./Pricing/Pricing";
import PriceChart from "./Pricing/PriceChart";
import { Helmet } from "react-helmet";
import Alert from "../Common/Alert";

const Home = () => {
    const myRef = useRef(null)
    const [files, setfiles] = useState([])
    const [compression_count, setcompression_count] = useState(1)
    let compression_limit = 26;
    const [imageData, setImageData] = useState([])
    const [imageArray, setImageArray] = useState([])
    const [totalPercentage, setTotalPercentage] = useState(0)
    const [enableBtn, setenableBtn] = useState(false)

    const [alertVal, setalertVal] = useState({
        showAlert: false,
        successAlert: false,
        alertMsg: ""
    })
    const toast = (alertOpen, alertType, msg) => {
        setalertVal({ ...alert, 'showAlert': alertOpen, 'successAlert': alertType, 'alertMsg': msg })
        setTimeout(() => {
            closeAlert()
        }, 3000);
    }
    const closeAlert = () => {
        setalertVal({ ...alert, 'showAlert': false, 'successAlert': false, 'alertMsg': "" })
    }

    let newImageData = []

    const recursiveMethod = (files, index) => {
        if (index > -1) {
            let formData = new FormData();
            formData.append('image', files[index]);
            getUploadData(formData, files, index)
        }
    }
    function comparer(otherArray) {
        return function (current) {
            return otherArray.filter(function (other) {
                return other.file_name == current.name
            }).length == 0;
        }
    }

    const handleOnDrop = async (file) => {
        if (file) {
            var onlyInA = file.filter(comparer(imageData));
            var onlyInB = imageData.filter(comparer(file));
            let result = onlyInA.concat(onlyInB);
            result.map((data, i) => {
                if (imageData.length < compression_limit && i < compression_limit) {
                    if (data.size > 25000000) {
                        newImageData.push({ file_name: data.name, original_size: data.size, oversize: true, success: true })
                    } else {
                        newImageData.push({ file_name: data.name, original_size: data.size })
                    }
                } else {
                    newImageData.push({ file_name: data.name, original_size: data.size, over: true })
                }
            })
            if (imageData.length < compression_limit) {
                let newResult = result.filter((opt) => opt.size < 25000000)
                recursiveMethod(newResult.slice(0, compression_limit), newResult.slice(0, compression_limit).length - 1);
            }
            setImageArray([...imageArray, ...newImageData])
            setImageData([...imageData, ...newImageData])
        }
    }

    const getUploadData = async (body, files, recIndex) => {
        recIndex = recIndex - 1
        recursiveMethod(files, recIndex);
        // let setProgressBarAvg = 1 / files.length;
        await Apis.PostApiMethod(constants.UPLOAD, body).then(res => {
            if (res.error === true) {
                if (res.res.data && res.res.data.file_name) {
                    let index = _.findIndex(files, function (val) {
                        return val.name == res.res.data.file_name
                    });
                    newImageData[index] = res.res.data
                    setImageData([...imageData, ...newImageData])
                }
                toast(true, false, res.response.data.error)
            } else {
                if (res.data && res.data.file_name) {
                    let index = _.findIndex(files, function (val) {
                        return val.name == res.data.file_name
                    });
                    newImageData[index] = res.data
                    setImageData([...imageData, ...newImageData])
                }
                if (res.data.success) {
                    setcompression_count(compression_count + 1)
                    let dropbox_files = []
                    newImageData.map((opt) => {
                        dropbox_files.push({ 'url': opt.compress_path, 'filename': opt.file_name })
                    })
                    setfiles(dropbox_files)
                }
            }
        })
    }
    const download = (path, name) => {
        saveAs(path, name);
    };

    const downloadAll = () => {
        let urls = imageData.filter((obj) => obj.success && obj.compress_path).map((opt) => {
            return opt.compress_path
        })
        generateZipFromCloud(urls)
    }

    useEffect(() => {
        let fileLength = imageData.filter((opt) => opt.success).length
        let fileArrLength = imageArray.filter((opt) => !opt.over).length
        if (fileArrLength === fileLength) {
            setenableBtn(true)
        } else {
            setenableBtn(false)
        }

        let percentArray = []
        imageData.map((data) => {
            percentArray.push(data.compress_percentage ? parseInt(data.compress_percentage.split("%")[0]) : 0)
        })
        var sum = _.reduce(percentArray, function (memo, num) {
            return memo + num;
        }, 0);
        setTotalPercentage(parseInt(sum / percentArray.length))
    }, [imageData, imageArray])


    const executeScroll = () => {
        myRef.current.scrollIntoView()
    }

    return (
        <>
            <Helmet>
                <title>Compress PNG and JPG files intelligently and instantly</title>
            </Helmet>
            <div className="headerbg">
                <Banner
                    handleOnDrop={handleOnDrop}
                />
            </div>
            <Result
                imageData={imageData}
                totalPercentage={totalPercentage}
                download={download}
                downloadAll={downloadAll}
                enableBtn={enableBtn}
                executeScroll={executeScroll}
                files={files}
            />
            <Compare />
            <About />
            <Testimonial />
            <Pricing ref={myRef} />
            <PriceChart />
            <div className="trustedcompany" >
                <div className="container">
                    <p>PNG Compressor works out of the box for these companies</p>
                    <ul className="navbar-nav nav row trusted_slide ">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>
            <Alert
                showAlert={alertVal.showAlert}
                successAlert={alertVal.successAlert}
                alertMsg={alertVal.alertMsg}
            />
        </>
    )
}

export default Home