import React, { useState } from "react";
import loginimg from "../../../assets/images/loginimg.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import _ from "underscore";
import * as Apis from "../../../config/Api";
import { constants } from "../../../config/constants";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";
import { Buffer } from 'buffer';

const ResetPassword = () => {
    let history = useHistory()
    let param = Buffer.from(history.location.search && history.location.search.split("?key=")[1], 'base64').toString('ascii')
    const [value, setValue] = useState({
        new_password: "",
        renew_password: "",
    })
    const [alertVal, setalertVal] = useState({
        showAlert: false,
        successAlert: false,
        alertMsg: ""
    })
    const [showText, setshowText] = useState(false)
    var reg = new RegExp(
        "^(?=.*)(?=.*[a-z])(?=.*[!@#%&])(?=.*[0-9])(?=.*[A-Z]).{8,32}$"
    );
    var validPass = reg.test(value.new_password);

    const toast = (alertOpen, alertType, msg) => {
        setalertVal({ ...alert, 'showAlert': alertOpen, 'successAlert': alertType, 'alertMsg': msg })
        setTimeout(() => {
            closeAlert()
        }, 3000);
    }
    const closeAlert = () => {
        setalertVal({ ...alert, 'showAlert': false, 'successAlert': false, 'alertMsg': "" })
    }
    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
        if(!validPass){
            setshowText(true)
        }else{
            setshowText(false)
        }
    }

    const submitFn = (body) => {
        Apis.PutApiMethod2(constants.UPDATE_PASSWORD, body, param).then(res => {
            if (res.error === true) {
                toast(true, false, res.response.data.error)
            } else {
                if (res.data.success === true) {
                    toast(true, true, res.data.message)
                    Apis.setToken(res.data.token)
                    setTimeout(() => {
                        history.push("/")
                    }, 3000);
                }
            }
        })
    }

    const submitResetPassword = (e) => {
        e.preventDefault();
        let valid = true
        if (value.new_password === "") {
            toast(true, true, 'Please enter your password')
            valid = false
        }
        else if (value.renew_password === "") {
            toast(true, true, 'Please confirm your password')
            valid = false
        }
        else if (value.new_password !== value.renew_password) {
            toast(true, true, 'Password not same')
            valid = false
        }
        else if (!validPass) {
            toast(true, true, 'Password not valid')
            valid = false
        }
        else {
            valid = true
        }
        if (valid) {
            let data = { new_password: value.new_password }
            submitFn(data)
        }

    }
    return (
        <div className="headerbg loginform">
            <Helmet>
                <title>Reset your password - PNG Compressor</title>
            </Helmet>
            <div className="login_form">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="row justify-content-center">
                                <div className="col-sm-5 col-md-6 title_forms">
                                    <strong>Welcome to <br />PNG Compressor</strong>
                                    <img src={loginimg} alt="loginimg" className="img-fluid" width="335" />
                                </div>
                                <div className="col-sm-7 col-md-6">
                                    <div className="tabbox">
                                        <ul className="nav nav-tabs justify-content-center">
                                            <li><Link to="/signup" className="forgot active">Forgot Password</Link></li>
                                        </ul>
                                        {alertVal.showAlert &&
                                            <>
                                                {alertVal.successAlert ?
                                                    <div className="alert alert-success">{alertVal.alertMsg}</div>
                                                    :
                                                    <div className="alert alert-danger errorMesg">{alertVal.alertMsg}</div>
                                                }
                                            </>
                                        }
                                        <div className="tab-content">
                                            <form onSubmit={submitResetPassword}>
                                                <div className="form-group">
                                                    <label>New password</label>
                                                    <div className="input-group">
                                                        <span className="input-group-addon">
                                                            <i><FontAwesomeIcon icon={faLock} /></i>
                                                        </span>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            name="new_password"
                                                            value={value.new_password}
                                                            placeholder="Please enter password"
                                                        />
                                                    </div>
                                                    {showText &&
                                                        <p><span id="validation_message" style={{ color: "red" }}>You must include a capital,lower character,one number,one special character and minimum 8 character</span></p>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label>Confirm password</label>
                                                    <div className="input-group">
                                                        <span className="input-group-addon">
                                                            <i><FontAwesomeIcon icon={faLock} /></i>
                                                        </span>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            name="renew_password"
                                                            value={value.renew_password}
                                                            placeholder="Please confirm password"
                                                        />
                                                    </div>
                                                </div>
                                                <button className="btn btn-block">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword