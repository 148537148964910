import React from "react";

const Loading = ({show, showLoading}) => {
    return (
        <>
            {show &&
                <div className="loading_page"></div>
            }
            {showLoading &&
                <div className="section_loading"></div>
            }
        </>
    )
}

export default Loading;